<template>
    <n-select placeholder="Search..." v-model:value="searchValue"  @search="handleUpdateValue" :options="searchOptions"  filterable clearable remote ref="searchBar" :loading="isLoading"/>

</template>


<script>

import { NSelect } from 'naive-ui';
export default {
	name: 'ExaminerSearchBar',
	components: {
        NSelect
	},
    created() {
    },
    
    async mounted() {
        this.searchValue = this.initSearchValue;
        if (this.emitMode) {
            if (this.searchValue !== null) {
                await this.getExaminer(this.searchValue);
            }  
        }
            

        this.isFirstLoad = false;
	},

    watch: {
        searchValue() {
            if (!this.isFirstLoad) {
                if (this.emitMode) {
                    this.$emit('searchValueSelected', this.searchValue)
                }
                
            }
            
        }
    },

    props: {
        initSearchValue: [Number, String],
        emitMode: {
            type: Boolean,
            default: true
        },
        inactiveMode: {
            type: Boolean,
            default: false
        }
    },

	data() {
		return {
            isFirstLoad: true,
            isLoading: false,
            searchOptions: [],
            searchValue: null,
        }
    },
    methods: {

        /**
         * Resets options and selected value when the search term is changed.
         */
        resetOptions() { 
            this.searchOptions = [];
            this.searchValue = null;
        },

        /**
         * Handles a value change in a search bar for Examiners
         * Requests to populate the n-select after a value change.
         */
        async handleUpdateValue(query) {
            if (!query.length) {
                this.searchOptions = [];
                return;
            }
            this.isLoading = true;
            // this.searchValue = null;
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/examiner/1/" + this.$refs.searchBar.$el.childNodes[1].childNodes[0].childNodes[0].value + "/first_name/first_name", {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },


            })
            let data = await response.json();

            this.searchOptions = [];
            for (let i in data.slice(0, -1)) {
                this.searchOptions.push({
                    label: `${ this.inactiveMode && !data[i].active_examiner ? "(Inactive) " : ""} ${data[i].first_name} ${data[i].last_name}${ data[i].rpas === true ? " (RPAS)" : data[i].caaid === null ? "" : " (" + data[i].caaid + ")"}`,
                    value: data[i].id,
                    disabled: this.inactiveMode ? !data[i].active_examiner : false
                })
            }
    
        
            if (this.searchOptions.length === 0) {
                this.searchOptions.push({
                    label: `No examiners found called '${query}'`,
                    value: "",
                    disabled: true,
                    style: {
                        color: "red"
                    }
                })
            }
            this.isLoading = false;
        },

        /**
         * Gets an examiner, called on page load
         * @param {*} examinerId 
         */
        async getExaminer(examinerId) {
            let requestURL = `/api/examiner/` + examinerId;
            
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            let data = await response.json();
            // this.searchOptions = [{label: data.first_name + " " + data.last_name, value: data.id}];
            // if (this.searchOptions.length === 1) {
            //     this.searchValue =  this.searchOptions[0].label;
            // }

            this.searchOptions = [{label: `${data.first_name} ${data.last_name}${ data.is_rpas ? " (RPAS)" : data.caaid === null ? "" : " (" + data.caaid + ")"}`, value: data.id}];
            this.searchValue = data.id;

        },

        setLabel(value) {
            this.searchValue = value
        }
    }
 
   }
   </script>
   