<template>
    <div class="" v-if="!isForbidden">
        <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Edit Workorder</h1>
		</div>
        <div v-if="!isLoading">
            <n-form class="p-4" size="large" :model="formValues" :rules="rules">

                <n-form-item
                    class=""
                    label="ID"
                    path="id"
                    >
                    <n-input placeholder="" disabled v-model:value="formValues.id"/>
                </n-form-item>

                <div class="md:flex justify-between space-x-0 md:space-x-5">
                    <n-form-item
                    v-if="isAdmin"
                    class="w-60"
                    label="Examiner"
                    :validation-status="isInvalidExaminer"
                    :feedback="examinerFeedback"
                    path="examiner">
                    <ExaminerSearchBar ref="examinerSearchBar" :init-search-value="this.examiner" @search-value-selected="(value) => this.examiner = value" :inactive-mode="true"></ExaminerSearchBar>

                </n-form-item>
                <n-form-item
                    v-else
                    class="w-60"
                    label="Examiner"
                    path="examiner"
                    :validation-status="isInvalidExaminer"
                    :feedback="examinerFeedback">
                    <ExaminerSearchBar ref="examinerSearchBar" :emit-mode="false" :init-search-value="this.examiner" @search-value-selected="(value) => this.examiner = value" disabled></ExaminerSearchBar>
                </n-form-item>
                    <n-form-item
                        class="w-60"
                        label="Candidate"
                        path="candidates"
                        :validation-status="isInvalidCandidate"
                        :feedback="candidateFeedback">
                        <CandidateSearchBar ref="candidateSearchBar" :init-search-value="this.candidate_id" @search-value-selected="(value) => this.candidate_id = value"></CandidateSearchBar>
                    </n-form-item>  
                </div>


                <n-checkbox v-model:checked="formValues.isCombo" class="pb-1">
                    <span class="font-bold text-red-600">
                        Combo Assessment
                    </span>
                </n-checkbox>
                <n-form-item
                    class=""
                    label="Assessment"
                    path="assessment"
                    :validation-status="isInvalidAssessment"
                    :feedback="assessmentFeedback"
                >
                    <n-select v-model:value="assessment" :options="assessmentOptions" filterable clearable/>
                </n-form-item>

                
                <n-form-item
                        class="pt-4"
                        label="Organisation"
                        path="organisation">
                        <n-input placeholder="" v-model:value="this.formValues.organisation" maxlength="200" show-count />
                </n-form-item>

                <div class="md:flex justify-between space-x-0 md:space-x-2.5">
                    <n-form-item
                        class=""
                        label="Planned Date"
                        path="date"
                        :validation-status="isInvalidDate"
                        :feedback="dateFeedback"
                    >
                        <n-date-picker v-model:value="this.formValues.date" type="date" format="dd/MM/yyyy" class="w-full md:w-60" :is-date-disabled="isDateRangeDisabled" />
                    </n-form-item>
                    

                    <n-form-item
                        class="w-60"
                        label="Status"
                        path="status"
                        :validation-status="isInvalidStatus"
                        :feedback="statusFeedback"
                        >
                        <n-select v-model:value="status" :options="statusOptions" filterable :clearable="isAdmin" :disabled="!isAdmin" />
                    </n-form-item>
                </div>

                <div v-if="this.status !== null && this.status !== 'Pending'" class="border-b border-gray-200 mx-4 mb-4">
                    <n-divider class="text-gray-500">
                        {{ this.status }} Options
                    </n-divider>
                    
                    <div>
                        <div v-if="this.status === 'Completed'">

                            <div class="flex justify-center">
                                <n-form-item 
                                class=""
                                label=""
                                path="isPass"
                                :validation-status="isInvalidIsPass"
                                :feedback="isPassFeedback">
                                    <div class="flex justify-center w-full">
                                        <n-radio :disabled="!isAdmin"  :checked="formValues.isPass === false" @update:checked="formValues.isPass = false">Failed</n-radio>
                                        <n-radio :disabled="!isAdmin"  :checked="formValues.isPass === true" @update:checked="formValues.isPass = true">Passed</n-radio>
                                    </div>
                                    
                                </n-form-item>
                                
                            </div>
                            <div class="flex justify-center pb-4">
                                
                            </div>

                            <n-form-item
                                
                                class=""
                                label="Date Completed"
                                path="completedDate"
                                :validation-status="isInvalidCompletedDate"
                                :feedback="completedDateFeedback">
                                <n-date-picker  :placeholder="this.formValues.date === null ? 'Please select a Planned Date first' : 'Select Date'" v-model:value="this.formValues.completedDate" type="date" format="dd/MM/yyyy" class="w-full" :is-date-disabled="isCompletedDateRangeDisabled" :disabled="this.formValues.date === null || !isAdmin" />
                            </n-form-item>
                        </div>

                        <n-form-item
                                v-if="this.status === 'Cancelled' || this.status === 'Deferred'"
                                class=""
                                label="Reason"
                                path="cancellationReason">
                                <n-input :disabled="!isAdmin" type="textarea" placeholder="" v-model:value="formValues.cancellationReason" maxlength="50" show-count/>
                        </n-form-item>

                    </div>

                
                </div>

                <n-form-item
                        class=""
                        label="Notes"
                        path="notes">
                        <n-input type="textarea" placeholder="" v-model:value="formValues.notes" maxlength="100" show-count/>
                </n-form-item>


                

            <div class="text-center md:text-left pt-4">
                <n-button
                class=""
                v-if="!isRequest"
                v-on:click="submit()"
                >
                    Save
                </n-button>
                <n-button
                class=""
                v-else
                >
                    <n-spin size="small"></n-spin>
                </n-button>
            </div>
            </n-form>
        </div>
        <div v-else class="flex justify-center p-5 w-96">
			<n-spin size="large"></n-spin>       
		</div>  

    </div>
    <div v-else>
		<Forbidden></Forbidden>
	</div>
</template>


<script>
    import { NForm, NFormItem, NButton, NInput, useMessage, NDatePicker, NSelect, NCheckbox, useLoadingBar , NSpin, NDivider, NRadio} from 'naive-ui'
    import { useUserStore } from '../../stores/UserStore'
    import { ref } from "vue"
    import Forbidden from '../../components/Forbidden.vue';
	import isAdmin from "../../helpers/AuthHelper.js";
    import  CandidateSearchBar from '../../components/CandidateSearchBar.vue'
    import  ExaminerSearchBar from '../../components/ExaminerSearchBar.vue'
export default {
	name: 'EditWorkorder',
	components: {
        NForm,
        NFormItem, 
        NButton,
        NInput,
        NDatePicker,
        NSelect,
        NCheckbox,
        NSpin,
        NDivider,
        NRadio,
        Forbidden,
        CandidateSearchBar,
        ExaminerSearchBar
	},
    created() {
        this.message = useMessage();
        this.loadingBar = useLoadingBar();
    },
    
    async mounted() {
        this.loadingBar.start()
		this.userStore = useUserStore();

        this.isForbidden = await this.getWorkorder();
        if (!this.isForbidden) {
            if (await isAdmin()) {
                this.isAdmin = true;
                this.statusOptions.push(
                    {
                    label: "Completed",
                    value: "Completed",
                    },
                    {
                    label: "Cancelled",
                    value: "Cancelled",
                    },
                    {
                        label: "Deferred",
                        value: "Deferred",
                    }
                )
            }
            
        }
        if (!this.isForbidden) {
            await this.getExaminers();
            await this.getAssessments();
        }
        
        
        this.loadingBar.finish();
        this.isLoading = false;
        this.isFirstLoad = false;

        this.$nextTick(() => {
            if (!this.isAdmin) {
            //Set the disabled examiner select
            if (this.userStore.id) {
                this.$refs.examinerSearchBar.setLabel(this.userStore.username);
                this.examiner = this.userStore.id;
            } else {
                //Need to wait for /api/me to run from navbar
                this.userStore.$subscribe(async (mutation) => {
                    if (mutation.events.key === "id") {
                        this.$refs.examinerSearchBar.setLabel(this.userStore.username);
                        this.examiner = this.userStore.id;
                    }
                })
            }
        }
        });

        
        
	},

    
    watch: {
        /**
         * Updates assessment options when an examiner is selected.
         */
        async examiner() {
            if (!this.isFirstLoad) {
                this.isLoadingAssessments = true;
                this.assessment = null;
                this.formValues.date = null;
                this.formValues.completedDate = null;
                this.formValues.isCombo = false;
                this.assessmentOptions = [];
                
                if (this.examiner !== null) {
                    this.loadingBar.start();
                    await this.getExaminers();
                    await this.getAssessments();
                    this.loadingBar.finish();
                }

                this.isLoadingAssessments = false;
            }
            

        },

        /**
         * Sets passed to null if attended is selected.
         */
        status() { 
            if (!this.isFirstLoad) {
                this.formValues.isPass = null;
            }

        },
    },

	data() {
		return {
            userStore: null,

            loadingBar: null,
            isRequest: false,
            message: null,
            createdDate: null,
            isLoading: true,
            isAdmin: false,
            isForbidden: false,
            isLoadingAssessments: false,
            isFirstLoad: true,

            isInvalidDate: "0",
            isInvalidCompletedDate: "0",
            isInvalidCandidate: "0",
            isInvalidAssessment: "0",
            isInvalidExaminer: "0",
            isInvalidStatus: "0",
            isInvalidIsPass: "0",
            dateFeedback: "",
            completedDateFeedback: "",
            candidateFeedback: "",
            assessmentFeedback: "",
            examinerFeedback: "",
            statusFeedback: "",
            isPassFeedback: "",

            examiner: "",
            candidate_id: null,
            assessment: "",
            status: "",

            formValues: ref({
                id: "",
                date: null,
                completedDate: null,
                cancellationReason: "",
                isCombo: false,
                organisation: "",
                notes: "",
                isPass: null,
            }),


            rules: {
                examiner: {
                    required: true,
                    message: "Please select an examiner",
                    
                },
                candidate_id: {
                    required: true,
                    message: "Please select a candidate",
               
                },
                assessment: {
                    required: true,
                    message: "Please select an assessment",
       
                },
                date: {
                    required: true,
                    message: "Please select a date",
        
                },
                status: {
                    required: true,
                    message: "Please select a status",
    
                },
                completedDate: {

                },
                cancellationReason: {

                },
                organisation: {
                },
                isCombo: {
                },
                notes: {

                }

            },

            statusOptions: [
                {
                label: "Pending",
                value: "Pending",
                }
            ],
            examinerOptions: [],
            examinerOptionDetails: [],
            assessmentOptions: []
        }
    },
    methods: {
        /**
         * Gets a specific workorder for editing.
         * Returns false on unauth
         */
        async getWorkorder() {
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/workorder/" + this.$route.params.id + "/", {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
                }
            })

            if (response.status === 403) {
                this.isLoading = false;
                return true;
            }

            let data = await response.json();
            data = data[0]
            
            this.formValues.id = [data.id];
            this.examiner = data.examiner_id;
            this.candidate_id = data.candidate_id;
            this.assessment = data.assessment.id.toString();
            this.formValues.isCombo = data.is_combo;
            this.formValues.organisation = data.organisation;
            this.formValues.date = data.date;
            this.formValues.completedDate = data.completion_date;
            this.status = data.status;
            this.formValues.cancellationReason = data.cancellation_reason;
            this.formValues.notes = data.notes;
            this.formValues.isPass = data.passed;

            this.createdDate = data.creation_date;

           
            return false;
        },

        /**
         * Requests to get a specific Examiner, used to populate options.
         */
        async getExaminers() {

            let requestURL = `/api/examiner/` + this.examiner;
				
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            let data = await response.json();
            this.examinerOptionDetails = [data];
            this.examiner = data.id;
        },

        /**
         * Requests to get Assessments, used to populate options.
         */
         async getAssessments() {
            this.isInvalidAssessment = "0";
            this.assessmentFeedback = "";
            this.assessmentOptions = [];

            
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/assessment/", {
					method: 'GET',
                    credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})


			let assessments = await response.json();
            let selectedExaminer = this.examinerOptionDetails.find(x => x.id === this.examiner);
            for (let i = 0; i < selectedExaminer.assessment_type.length; i ++) {
                let assessment = assessments.find(x => x.id === parseInt(selectedExaminer.assessment_type[i]))
                if (assessment !== undefined) {
                    this.assessmentOptions.push({
                        label: `${assessment.name}`,
                        value: assessment.id.toString(),
                    })
                }
            }
            
            if (this.assessmentOptions.length === 0) {
                this.isInvalidAssessment = "warning";
                this.assessmentFeedback = "This examiner does not have any assessment types.";
            }
        },

        /**
         * PUTs a JSON containing a workorder.
         */
        async submit() {
            this.isRequest = true;
            let data = {
                    "examiner": this.examiner,
                    "candidate_id":  this.candidate_id,
                    "assessment": this.assessment,
                    "date": this.formValues.date,
                    "creation_date": this.createdDate,
                    "completion_date": this.formValues.completedDate,
                    "status": this.status,
                    "cancellation_reason": this.formValues.cancellationReason,
                    "organisation": this.formValues.organisation,
                    "is_combo": this.formValues.isCombo,
                    "notes": this.formValues.notes,
                    "passed": this.formValues.isPass

                }
            const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]

            //Date checks
            if (this.examinerOptionDetails.find(x => x.id === this.examiner)) {
                let selectedExaminer = this.examinerOptionDetails.find(x => x.id === this.examiner)
                if (selectedExaminer.medical_expiry) {
                    selectedExaminer.ratings["medical_expiry"] = selectedExaminer.medical_expiry;
                }
                if (this.formValues.date > Math.min(...Object.values(selectedExaminer.ratings))) {
                    this.isInvalidDate = "error";
                    this.dateFeedback = "Date cannot be after this examiner's expiry."
                    this.message.warning(`Create unsuccessful. Please check all inputs.`);
                    this.isRequest = false;
                    return;
                }
                
            }

            //Check status & passed.
            if (this.status === "Completed") {
                let completedError = false;
                if (this.formValues.isPass === null) {
                    this.isInvalidIsPass = "error";
                    this.isPassFeedback = "Please select if this workorder was passed, or change the 'Status'."
                    this.message.warning(`Create unsuccessful. Please check all inputs.`);
                    this.isRequest = false;
                    completedError = true;
                }
                if (this.formValues.completedDate !== null && this.formValues.completedDate < this.formValues.date) {
                    this.isInvalidCompletedDate = "error";
                    this.completedDateFeedback = "Date Completed must be the same day or after the Workorder's Planned Date."
                    this.message.warning(`Create unsuccessful. Please check all inputs.`);
                    this.isRequest = false;
                    completedError = true;
                }
                if (completedError) {
                    return;
                }
            }
            
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/workorder/" +  this.$route.params.id + "/", {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookie
                },

                body: JSON.stringify(data)
            })

            if (response.status === 200) {
                this.userStore.event = "edit";
                this.$router.push(`/home/workorders/${this.$route.params.id}`)

            } else if (response.status === 400) {
                this.message.warning(`Edit unsuccessful. Please check all inputs.`);
                let data = await response.json();
                let keys = Object.keys(data)
                if (keys.includes("date")) {
                    this.isInvalidDate = "error";
                    this.dateFeedback = data["date"][0];
                }
                if (keys.includes("assessment")) {
                    this.isInvalidAssessment = "error";
                    this.assessmentFeedback = data["assessment"][0];
                }
                if (keys.includes("candidate_id")) {
                    this.isInvalidCandidate = "error";
                    this.candidateFeedback = data["candidate_id"][0];
                }
                if (keys.includes("examiner")) {
                    this.isInvalidExaminer = "error";
                    this.examinerFeedback = data["examiner"][0];
                }
                if (keys.includes("status")) {
                    this.isInvalidStatus = "error";
                    this.statusFeedback = data["status"][0];
                }
            } else if (response.status === 403) {
                this.message.error(`You are not an active examiner. Please contact an admin.`);
            } else if (response.status === 500) {
                this.message.error(`An unknown error occurred, please check your inputs and try again.`);
            }
            this.isRequest = false;
        },  
        
        /**
         * Disables the planned date picker for any date after the earliest expiry for an examiner.
         */
        isDateRangeDisabled(ts) {
            
            if (this.isAdmin) {
                if (this.examinerOptionDetails.find(x => x.id === this.examiner)) {
                    let selectedExaminer = this.examinerOptionDetails.find(x => x.id === this.examiner)
                    if (selectedExaminer.medical_expiry) {
                        selectedExaminer.ratings["medical_expiry"] = selectedExaminer.medical_expiry;
                    }
                    return ts > Math.min(...Object.values(selectedExaminer.ratings))
                }
                
            }
            return ts > this.userStore.earliestExpiry;
        },

        /**
         * Disables the completed date picker for any date before the planned date.
         */
        isCompletedDateRangeDisabled(ts) {
            return ts < this.formValues.date;
        },

        resetErrors() {
            this.isInvalidAssessment = "0";
            this.isInvalidCandidate = "0";
            this.isInvalidDate = "0";
            this.isInvalidExaminer = "0";
            this.isInvalidStatus = "0";
            this.isInvalidIsPass = "0";
            this.isInvalidCompletedDate = "0";
            this.dateFeedback = "";
            this.completedDateFeedback = "";
            this.candidateFeedback = "";
            this.assessmentFeedback = "";
            this.examinerFeedback = "";
            this.statusFeedback = "";
            this.isPassFeedback = "";
        },
    }

 
   }
   </script>
   