<template>
	<div v-if="!isForbidden">
		<div v-if="this.$route.path.split('/').length > 3" class="flex justify-center">
			<router-view :key="$route.fullPath" class="bg-white rounded-md border"></router-view>
		</div>
		<div class="bg-white rounded-md rounded-md border" v-else>
			<div class="bg-blue-500 rounded-t-md">
				<h1 class="text-2xl text-white px-4 py-2">Courses</h1>
			</div>
			<div class="p-4 px-8" v-if="!isLoading">
				<div class="flex justify-between md:justify-start">
					<span>
						<n-button icon-placement="left" v-on:click="clearFilter()" class="hidden md:flex">
							<template #icon>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
								</svg>
							</template>
							<span class="">
								Clear Filter
							</span>

						</n-button>
					</span>

					<div class="md:flex justify-between  w-full pr-4 space-y-2 md:space-y-0">

						<n-select placeholder="Search by" v-model:value="searchTerm" :options="searchOptions" filterable clearable />
						<div v-if="this.searchTerm === 'candidate' || this.searchTerm === 'course_type'"  class="md:w-full">
							<n-select v-if="this.searchTerm === 'course_type'" placeholder="Search..." v-model:value="searchValue" :options="optionalSearchOptions" filterable clearable ref="searchBar"/>
							<CandidateSearchBar v-if="this.searchTerm === 'candidate'" ref="candidateSearchBar" :init-search-value="this.searchValue" @search-value-selected="(value) => this.searchValue = value"></CandidateSearchBar>
						</div>
						<n-input v-else placeholder="Search..." v-model:value="searchValue" class="" clearable />
						<n-select placeholder="Sort by" v-model:value="sortOption" :options="sortOptions" filterable clearable :disabled="this.searchTerm === null || this.searchValue ===''" />

						<div class="flex justify-between">
							<n-button icon-placement="left" v-on:click="clearFilter()" class="flex md:hidden">
								<template #icon>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
									</svg>
								</template>
								<span class="">
									Clear Filter
								</span>

							</n-button>
							
							<span>
								<n-button type="" @click="searchCourses()">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
									</svg>

								</n-button>
							</span>
						</div>
					</div>
					<n-button icon-placement="middle" v-on:click="routeToCreate()" class="md:hidden">
						<template #icon>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
							</svg>
						</template>

					</n-button>

					<n-button icon-placement="left" v-on:click="routeToCreate()" class="hidden md:flex">
						<template #icon>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
							</svg>
						</template>
						<span class="">
							Create
						</span>

					</n-button>
				</div>
			
				<div class="overflow-x-auto">
					<n-space vertical class="pt-4">
					<n-table striped >
						<thead>
						<tr>
							<th>ID</th>
							<th>Type</th>
							<th>Title</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Ground Instructor</th>
							<th>Venue</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
							<tr v-for="course in this.structuredCourses" v-bind:key="course.id" class="cursor-pointer" @click="routeToCourseDetails(course.id)">
								<td><a class="">{{ course.id }}</a></td>
								<td>{{ this.courseTypesArray[course.course_type] }}</td>
								<td><a class="">{{ course.title }}</a></td>
								<td>{{ this.epochToDate(course.date_start) }}</td>
								<td>{{ this.epochToDate(course.date_end) }}</td>
								<td><a class="">{{ course.presenter }}</a></td>
								<td><a class="">{{ course.venue }}</a></td>
								<td><n-button v-on:click.stop="routeToCourseEdit(course.id)">Edit</n-button></td>
							</tr>
						</tbody>
					</n-table>
				</n-space>
				</div>
				
				<div class="flex justify-center pt-4">
					<n-space vertical class="">
						<n-pagination v-model:page="page" :page-count="this.pages" />
					</n-space>

				</div>
			</div>
			<div v-else class="flex justify-center p-5">
				<n-spin size="large"></n-spin>       
			</div>
		</div>
	</div>
	<div v-else class="bg-white">
		<Forbidden></Forbidden>
	</div>
</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NButton, NInput, NSpace, NTable, NPagination, NSelect, NSpin } from 'naive-ui';
   import { useLoadingBar } from 'naive-ui'
   import  CandidateSearchBar from '../../components/CandidateSearchBar.vue'
   import Forbidden from '../../components/Forbidden.vue';
   import isAdmin from "../../helpers/AuthHelper.js";
	export default {
		name: 'Courses',
		components: {
			NButton,
			NInput, 
			NSpace,
			NTable,
			NPagination,
			NSelect,
			NSpin,
			Forbidden,
			CandidateSearchBar
		},

		created() {
			this.loadingBar = useLoadingBar()
		},

		watch: {
			page() {
				this.searchCourses();
			},
			searchTerm() {
				if (!this.firstLoad) {
					this.resetOptions();
				}
			},
		},

		async mounted() {
			this.userStore = useUserStore();

			if (await isAdmin()) {
				this.populateFields();
			
				if (this.$route.path === "/home/courses") {
					this.loadingBar.start()
						await this.getCourses();
						await this.getCourseTypes();
					this.loadingBar.finish()
				}

			} else {
                this.isForbidden = true;
            }
			this.firstLoad = false;
			this.isLoading = false;


		},

		data() {
			return {
			userStore: null,
			loadingBar: null,
			isForbidden: false,
			isLoading: true,
			firstLoad: true,

			sortOption: null,
			sortOptions: [
				{
				label: `ID ASC`,
				value: 'id',
				},
				{
				label: `ID DESC`,
				value: '-id',
				},
		
				{
				label: `Title A->Z`,
				value: 'title',
				},
				{
				label: `Title Z->A`,
				value: '-title',
				},
	

				{
				label: `Presenter A->Z`,
				value: 'presenter',
				},
		
				{
				label: `Presenter Z->A`,
				value: '-presenter',
				},

				{
				label: `Venue A->Z`,
				value: 'venue',
				},
		
				{
				label: `Venue Z->A`,
				value: '-venue',
				},
			],

			searchOptions: [
				{
				label: `ID`,
				value: 'id',
				},
				{
				label: `Course Type`,
				value: 'course_type',
				},
				{
				label: `Candidate`,
				value: 'candidate',
				},
				{
				label: `Title`,
				value: 'title',
				},


				{
				label: `Ground Instructor`,
				value: 'presenter',
				},
		
				{
				label: `Venue`,
				value: 'venue',
				},
		
		
		
			],

			optionalSearchOptions: [
			],

			searchTerm: null,
			searchValue: null,
			isRequest: false,
			
			page: 1,
			pages: 0,
			courses: null,
			structuredCourses: null,
			courseTypesArray: {},

			}
		},
		methods: {

			/**
			 * Resets options and selected value when the search term is changed.
			 */
			resetOptions() { 
				if (this.$refs.candidateSearchBar) {
					this.$refs.candidateSearchBar.resetOptions();
				}

				this.searchValue = null;
			},

			populateFields() {
				let alphaNumRegex = new RegExp(/\W/g)

				this.page = parseInt(this.$route.query.page)
				if (isNaN(this.page)) {
					this.page = 1;
				}

				if (this.searchOptions.some(el => el.value === this.$route.query.searchTerm)) {
					this.searchTerm = this.$route.query.searchTerm
				}
			
				if (!alphaNumRegex.test(this.$route.query.searchValue)) {
					this.searchValue = this.$route.query.searchValue ? this.$route.query.searchValue : null
				}

				if (this.sortOptions.some(el => el.value === this.$route.query.sortOption)) {
					this.sortOption = this.$route.query.sortOption
				}

				

			},

			/**
			 * Clears all filters.
			 */
			clearFilter() {
				this.searchValue = null;
				this.searchTerm = null;
				this.sortOption = null;
			},


			epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},

			/**
			 * Gets courses,
			 * NOTE: If searching by candidate, the endpoint is different.
			 */
			async getCourses() {

				let requestURL = `/api/courses/${this.page}`;
				if (this.searchValue !== '' && this.searchValue !== undefined && this.searchValue !== null && this.searchTerm !== ""  && this.searchTerm !== null) {
					let sortOption = this.sortOption
					if (this.sortOption === null) {
						sortOption = "title"
					}
					if (this.searchTerm === 'candidate') {
						requestURL =  `/api/courses/${this.page}/${this.searchValue}/${sortOption.replace("-", "")}/${sortOption.startsWith('-') ? 1 : 0}`
					} else {
						requestURL =  `/api/course/${this.page}/${this.searchValue}/${this.searchTerm}/${sortOption}`
					}
					
				}

				let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
				this.courses = await response.json();
				this.structuredCourses = this.courses.slice(0, -1);
				this.pages = this.courses[this.courses.length - 1].num_pages
			},

			async getCourseTypes() {
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/coursetype", {
                        method: 'GET',
						credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                let data = await response.json();
				for (let entry of data) {
					this.courseTypesArray[entry.id] = entry.name
					this.optionalSearchOptions.push({label: entry.name, value: entry.id});
				}

				if (this.$route.query.searchValue && this.searchTerm === "course_type") {
					this.searchValue = this.optionalSearchOptions.find((a) => {
						return a.value.toString() === this.$route.query.searchValue
					}).value;
				}
            },

			async searchCourses() {
				this.$router.push({ path: '/home/courses', query: { page: this.page, searchTerm: this.searchTerm, searchValue: this.searchValue, sortOption: this.sortOption }})
			},

			routeToCreate() {
				this.$router.push('/home/courses/create')

			},

			routeToCourseEdit(courseId) {
				this.$router.push('/home/courses/edit/' + courseId)
			},

			routeToCourseDetails(courseId) {
				this.$router.push('/home/courses/' + courseId)
			},
			routeToCandidate(candidateId) {
				this.$router.push('/home/candidates/' + candidateId)
			}
		}
	}
   </script>
   