<template>
	<div v-if="isNotFound || isForbidden || isRequestError">
		<div v-if="isNotFound" class="bg-white">
			<NotFound></NotFound>
		</div>
		<div v-if="isForbidden" class="bg-white">
			<Forbidden></Forbidden>
		</div>
		<div v-if="isRequestError" class="bg-white">
			<RequestError :request-error-code="this.requestErrorCode"></RequestError>
		</div>
	</div>
	<div v-else>
		<div v-if="this.$route.path.split('/').length > 4" class="flex justify-center">
			<router-view :key="$route.fullPath" class="bg-white rounded-md border"></router-view>
		</div>
		<div class="bg-white rounded-md rounded-md border" v-else>
			<div class="bg-blue-500 rounded-t-md">
				<h1 class="text-2xl text-white px-2 py-2">Profile</h1>
			</div>
			<div class="p-4 px-8 md:flex justify-between text-gray-600 w-full w-120 overflow-x-auto" v-if="!isLoading">
				<div class="w-full px-4">
					<div class="flex justify-between">
						<div class="text-lg text-gray-700 flex items-center space-x-2">
							<div class="">
								<n-tag>{{this.examiner.active_examiner ? "Active" : "Inactive"}}</n-tag>
							</div>
							
							<div class="">
								<n-tag>Licence: {{this.examiner.pilot_licence_type}}</n-tag>
							</div>
							<div class="" v-if="this.examiner.rpas">
								<n-tag>RPAS</n-tag>
							</div>
						</div>
						

						<div>
							<NButton @click="routeToExaminerEdit()">Edit</NButton>      
						</div>
					</div>

					<div class="text-lg text-gray-700 flex items-center">
						<div class="text-xl">
							{{ this.examiner.first_name }} {{ this.examiner.last_name }}
						</div>
					</div>

					<div class="">
						<div class="text-base flex items-center">
							<div class="font-semibold pr-3" v-if="this.examiner.email">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
									<path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
								</svg>
							</div>
								<div class="">
									{{ this.examiner.email }}
								</div>
						</div>
						<div class="text-base flex items-center">
							<div class="font-semibold pr-3" v-if="this.examiner.phone">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
									<path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
								</svg>
							</div>
							
							<div class="">
								{{ this.examiner.phone }}
							</div>
						</div>

						<div v-if="this.examiner.caaid !== null" class="pt-2 text-base">
							<div class="font-semibold">
								CAAID
							</div>
							<div>
								{{ this.examiner.caaid }}
							</div>
						</div>

						
						<div v-if="this.examiner.assessment_type.length !== 0" class="pt-2 text-base">
							<div class="font-semibold">
								Assessment Types
							</div>
							<div v-for="assessment_id in this.examiner.assessment_type" v-bind:key="assessment_id">
								{{ this.assessments[assessment_id] }}
							</div>	
						</div>
					</div>
				</div>

				
	

				<div class="border-grey-200 md:border-l text-gray-600 px-2 w-full md:w-96 pt-10 md:pt-0">
					<div class="font-bold">
						Expiry Dates
					</div>
					<div v-if="this.ratingsDays['medical_expiry'] !== undefined" class="flex items-center justify-between" :class=" this.ratingsDays['medical_expiry'] === 0 ? 'text-red-600': ''">
						<span class="flex items-center">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="this.ratingsDays['medical_expiry'] > 1 ||  this.ratingsDays['medical_expiry'] === -1 ? '#f97316' : '#dc2626'" class="w-4 h-4" v-if="this.ratingsDays['medical_expiry'] > 1 ||  this.ratingsDays['medical_expiry'] <= 0">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
							</svg>
							
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#16a34a" class="w-4 h-4" v-if="this.ratingsDays['medical_expiry'] === 1">
								<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>

							<span v-if="this.ratingsDays['medical_expiry'] > 1">{{ this.ratingsDays["medical_expiry"] }} Days</span>
							<span v-if="this.ratingsDays['medical_expiry'] === -1">1 Day</span>
						</span>
						
						
						Medical {{this.epochToDate(this.examiner.medical_expiry)}}
					</div>
					<div class="flex items-center justify-between" v-else>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#dc2626" class="w-4 h-4">
							<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
						</svg>
						<span class="text-red-600">
							Medical Expiry does not exist
						</span>
					</div>
					<div v-for="ratingKey in Object.keys(this.examiner.ratings)" v-bind:key="ratingKey">
						<div class="flex items-center justify-between">
							<span class="flex items-center">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="this.ratingsDays[ratingKey] > 1 ||  this.ratingsDays[ratingKey] === -1 ? '#f97316' : '#dc2626'" class="w-4 h-4" v-if="this.ratingsDays[ratingKey] > 1 ||  this.ratingsDays[ratingKey] <= 0">
									<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
								</svg>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#16a34a" class="w-4 h-4" v-if="this.ratingsDays[ratingKey] === 1"> 
									<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg>


								<span v-if="this.ratingsDays[ratingKey] > 1">{{ this.ratingsDays[ratingKey] }} Days</span>
								<span v-if="this.ratingsDays[ratingKey] === -1">1 Day</span>
							</span>

							<span :class=" this.ratingsDays[ratingKey] === 0 ? 'text-red-600': ''">
								{{ratingKeyToString(ratingKey)}} 
								{{this.epochToDate(this.examiner.ratings[ratingKey])}} 
							</span>

							
						</div>
					</div>

				</div>

				

				
			</div>
			
			<div v-else class="flex justify-center p-5 w-120">
				<n-spin size="large"></n-spin>       
			</div>
		</div>
	</div>
</template>


<script>
	import { useUserStore } from '../stores/UserStore'
	import { NButton, NTag, NSpin, useMessage } from 'naive-ui';
	import { useLoadingBar } from 'naive-ui'
	import Forbidden from '../components/Forbidden.vue';
	import isAdmin from "../helpers/AuthHelper.js";
	import RequestError from '../components/RequestError.vue';
    import NotFound from '../components/NotFound.vue';
	export default {
		name: 'Profile',
		components: {
			NButton,
			NTag,
			NSpin,
			Forbidden,
			RequestError,
			NotFound
		},

		created() {
			this.loadingBar = useLoadingBar();
			this.message = useMessage();
		},
		async mounted() {
			this.loadingBar.start();

			this.userStore = useUserStore();
			this.handleLoadEvent();

			if (this.userStore.id) {
				await this.loadProfile();
			} else {
				//Need to wait for /api/me to run from navbar
				this.userStore.$subscribe(async (mutation) => {
					if (mutation.events.key === "id") {
						await this.loadProfile();
					}
				})
			}

		},

		data() {
			return {
				userStore: null,
				loadingBar: null,
				isLoading: true,
				isForbidden: false,
				isNotFound: false,
				isRequestError: false,
				requestErrorCode: 0,
				message: null,
				
				isRequest: false,
				assessments: {},
				examiner: {
					ratings: "",
					medical_expiry: ""
				},
				ratingsDays: {}
			}
		},
		methods: {

			/**
			 * Displays a message based on the previous page's action.
			 */
			handleLoadEvent() {
				switch (this.userStore.event) {
				case "create":
					this.message.success("Examiner created successfully.");
					break;
					case "edit":
					this.message.success("Profile edited successfully.");
					break;
					case "editAdmin":
					this.message.success("Examiner edited successfully.");
					break;
				}
				this.userStore.event = null;
			},

			/**
			 * Checks permissions, then loads the correct profile.
			 */
			async loadProfile() {
				if (this.userStore.id.toString() === this.$route.params.id) {
					await this.getAllExaminerDetails();
					this.isForbidden = false;
				} else {
					if (await isAdmin()) {
						await this.getAllExaminerDetails();
					} else {
						this.isForbidden = true;
					}
					
				}
				this.loadingBar.finish();
				this.isLoading = false;	
			},

			ratingKeyToString(key) {
				switch(key) {
					case "ga_rating":
						return "GA Expiry"
					case "airline_rating":
						return "Airline Expiry";
					case "rpas_rating":
						return "RPAS Expiry"
				}
			},

			epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},

			async getAllExaminerDetails() {
				await this.getExaminer();
				await this.getRatings();
				await this.getAssessments();
			},

			async getRatings() {
				let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/examiner/" + this.$route.params.id + "/ratings", {
                    method: 'GET',
					credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
				if (response.status === 200) {
					let data = await response.json();
					this.ratingsDays = data;
				} else if (response.status === 404) {
                    this.isNotFound = true;
                } else {
                    this.requestErrorCode = response.status;
                    this.isRequestError = true;
                }
               
			},


			async getExaminer() {
				let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/examiner/" + this.$route.params.id, {
                    method: 'GET',
					credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
				if (response.status === 200) {
					let data = await response.json();
					this.examiner = data;
				} else if (response.status === 404) {
                    this.isNotFound = true;
                } else {
                    this.requestErrorCode = response.status;
                    this.isRequestError = true;
                }
				
			},

			/**
         * Requests to get Assessments.
         */
         async getAssessments() {
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/assessment/", {
					method: 'GET',
                    credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
			if (response.status === 200) {
				let assessments = await response.json();
				for (let i = 0; i < assessments.length; i ++) {
					this.assessments[assessments[i].id.toString()] = `${assessments[i].name}`
				}
			} else if (response.status === 404) {
				this.isNotFound = true;
			} else {
				this.requestErrorCode = response.status;
				this.isRequestError = true;
			}
			
        },

			routeToExaminerEdit() {
				this.$router.push('/home/examiners/edit/' + this.examiner.id);
			},

		
		}
	}
   </script>
   