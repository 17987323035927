<template>
    <n-card
        style="width: 600px"
        title="Edit Course Candidate"
        :segmented="{
        content: true,
        footer: 'soft'
        }"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        >
        <div v-if="!isEditRequest">
            <n-form class="p-4" size="large" :model="formValues" :rules="rules">

                <div class="flex justify-end">
                    <n-button @click="this.$emit('showCreateCandidate')">Create Candidate</n-button>       
                </div>

            <div class="pt-2 md:flex justify-between">
                <n-form-item
                    class="w-full md:w-60"
                    label="Course Type"
                    path="type"
                    >
                    <n-input placeholder="" disabled v-bind:value="[this.courseType]"/>
                </n-form-item>
                
                <n-form-item
                    class="w-full md:w-60"
                    label="Course ID"
                    path="id"
                    >
                    <n-input placeholder="" disabled v-bind:value="[this.courseId]"/>
                </n-form-item>
            </div>

            <n-form-item
                class=""
                label="Candidate"
                path="candidates"
                :validation-status="isInvalidCandidate"
                :feedback="candidateFeedback">
                <CandidateSearchBar ref="candidateSearchBar" :init-search-value="this.candidate" @search-value-selected="(value) => this.candidate = value"></CandidateSearchBar>
            </n-form-item>  
                        
            

            <n-form-item
                    class=""
                    label="Invoice Number"
                    path="invoiceNumber">
                    <n-input placeholder="" v-model:value="this.formValues.invoiceNumber" maxlength="200" show-count />
            </n-form-item>

            <n-form-item
                    class=""
                    label="Invoicee"
                    path="invoicee">
                    <n-input placeholder="" v-model:value="this.formValues.invoicee" maxlength="200" show-count />
            </n-form-item>

            <n-checkbox v-model:checked="formValues.isLetterSent" class="pb-2">
                Letter Sent
            </n-checkbox>

            <n-checkbox v-model:checked="formValues.isInvoice" class="pb-2">
                Invoiced
            </n-checkbox>

            <n-checkbox v-model:checked="formValues.hasPaid" class="pb-2">
                Paid
            </n-checkbox>

            <n-checkbox v-model:checked="formValues.hasAttended" class="pb-2">
                Attended
            </n-checkbox>

            <div v-if="formValues.hasAttended">
                <n-divider class="text-gray-600">
                    
                </n-divider>
                <div class="flex justify-center">
                    <n-form-item 
                    class=""
                    label=""
                    path="passed"
                    :validation-status="isInvalidPassed"
                    :feedback="passedFeedback">
                    <div class="flex justify-center w-full">
                        <n-radio :checked="formValues.passed === false" @update:checked="formValues.passed = false">Failed</n-radio>
                        <n-radio :checked="formValues.passed === true" @update:checked="formValues.passed = true">Passed</n-radio>
                    </div>
                        
                    </n-form-item>
                    
                </div>
            </div>

            <n-form-item
                    class=""
                    label="Notes"
                    path="notes">
                    <n-input type="textarea" placeholder="" v-model:value="formValues.notes" maxlength="100" show-count/>
            </n-form-item>


            

            
            <div class="text-center md:text-left pt-4">
                <n-button
                class=""
                :loading="isRequest"
                v-on:click="submit()"
                >
                    Save
                </n-button>
                </div>
            </n-form>     
        </div>
        <div v-else class="flex justify-center p-5">
			<n-spin size="large"></n-spin>       
		</div>  
        </n-card>
</template>

<script>
   import { NButton, useLoadingBar, NCard, NForm, NFormItem, NInput, NSpin, NCheckbox, useMessage, NRadio, NDivider } from 'naive-ui';
   import { ref } from "vue"
   import candidateString from "../helpers/CandidateHelper";
   import  CandidateSearchBar from './CandidateSearchBar.vue'

	export default {
		name: 'EditCandidateCourseDetail',
		components: {
            NButton,
            NCard,
            NForm,
            NFormItem, 
            NInput,
            NSpin,
            NCheckbox,
            CandidateSearchBar,
            NRadio,
            NDivider
		},
        async mounted() {

            this.isEditRequest = true;
            await this.getCandidateCourseDetail(this.selectedCourseDetailId);         
            await this.getCandidates();
            this.isEditRequest = false;
            this.isFirstLoad = false;
        },

        props: {
            courseType: String,
            selectedCourseDetailId: Number, 
        },

        watch: {
            /**
             * Sets passed to null if attended is selected.
             */
            'formValues.hasAttended'() { 
                if (!this.isFirstLoad) {
                    this.formValues.passed = null;
                }

            }
        },

        created() {
			this.loadingBar = useLoadingBar();
            this.message = useMessage();
		},

		data() {
			return {
			message: null,
            isRequest: false,
            isEditRequest: false,
            isFirstLoad: true,

            showCreateCandidate: false,
            candidateCourseDetails: [],
            courseId: "",

            candidateFeedback: "",
            isInvalidCandidate: "0",
            passedFeedback: "",
            isInvalidPassed: "0",

            candidateOptions: [],
            candidate: null,
            formValues: ref({
                invoiceNumber: null,
                invoicee: null,
                hasPaid: false,
                hasAttended: false,
                isLetterSent: false,
                isInvoice: false,
                passed: null,
                notes: "",
            }),
            rules: {
                candidates: {
                    required: true,
                    message: "Please select a candidate",
                    
                },

            }

			}
		},
		methods: {

            /**
             * Requests to get a specific CandidateCandidateCourseDetail
             */
             async getCandidateCourseDetail(candidateCourseId) {
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidatecoursedetail/" + candidateCourseId, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                let data = await response.json();
                this.courseId = data.course_id;
                this.candidate = data.candidate_id.toString()
                this.formValues.invoiceNumber = data.invoice_number;
                this.formValues.invoicee =  data.invoicee;
                this.formValues.hasPaid = data.has_paid;
                this.formValues.hasAttended = data.has_attended;
                this.formValues.isLetterSent = data.letter_sent;
                this.formValues.isInvoice = data.invoiced;
                this.formValues.passed = data.passed;
                this.formValues.notes = data.notes;
                    
            },

            /**
             * Requests to get Candidates, used to populate options.
             */
            async getCandidates() {
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidate/" + this.candidate, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                let data = await response.json();

                this.candidateOptions = [{label: data.first_name + " " + data.last_name, value: data.id.toString()}];
                this.candidates = data.id.toString();
            },

            async handleCandidateSelect() {
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidate/1/" + this.$refs.candidateSearch.$el.childNodes[1].childNodes[0].childNodes[0].value + "/first_name/first_name/0", {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },


                })
                let data = await response.json();

                this.candidateOptions = [];
                for (let i in data.slice(0, -1)) {
                    this.candidateOptions.push({
                        label: candidateString(data[i]),
                        value: data[i].id.toString(),
                    })
                }
            },

            async submit() {
                this.resetErrors();
                this.isRequest = true;
                let data = {
                        "course_id": this.courseId,
                        "invoice_number": this.formValues.invoiceNumber === '' ? null  : this.formValues.invoiceNumber,
                        "invoicee": this.formValues.invoicee === '' ? null  : this.formValues.invoicee,
                        "has_paid": this.formValues.hasPaid,
                        "has_attended": this.formValues.hasAttended,
                        "invoiced": this.formValues.isInvoice,
                        "letter_sent": this.formValues.isLetterSent,
                        "candidate_id": parseInt(this.candidate),
                        "passed": this.formValues.hasAttended ? this.formValues.passed : null,
                        "notes": this.formValues.notes,
                    }

                const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]


                //Check attended & passed.
                if (this.formValues.hasAttended) {
                    if (this.formValues.passed === null) {
                        this.isInvalidPassed = "error";
                        this.passedFeedback = "Please select if this candidate has passed, or unselect 'Attended'."
                        this.message.warning(`Edit unsuccessful. Please check you have entered all required inputs.`);
                        this.isRequest = false;
                        return;
                    }
                }

                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidatecoursedetail/" + this.selectedCourseDetailId + "/", {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': cookie
                    },

                    body: JSON.stringify(data)
                })

                if (response.status === 200) {
                    this.$emit('editCandidateCourseDetailSuccess');
                } else {
                    let data = await response.json();
                    let keys = Object.keys(data)
                    if (keys.includes("candidate_id")) {
                        this.isInvalidCandidate = "error";
                        this.candidateFeedback = data["candidate_id"][0];
                    }
                    this.message.warning(`Edit unsuccessful. Please check you have entered all required inputs.`);
                    this.isRequest = false;
                }
                
            },

            resetErrors() {
                this.isInvalidCandidate = "0";
                this.candidateFeedback = "";
                this.isInvalidPassed = "0";
                this.passedFeedback = "";
            },
		}
	}
   </script>
   