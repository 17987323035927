<template>
	<div v-if="this.$route.path.split('/').length > 3" class="flex justify-center">
		<router-view :key="$route.fullPath" class="bg-white rounded-md border"></router-view>
	</div>
	<div class="bg-white rounded-md rounded-md border" v-else>
		<div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Candidates</h1>
		</div>
		<div class="p-4 px-8" v-if="!isLoading">
			<div class="flex justify-between md:justify-start">

				<span>
					<n-button icon-placement="left" v-on:click="clearFilter()" class="hidden md:flex">
						<template #icon>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
							</svg>
						</template>
						<span class="">
							Clear Filter
						</span>

					</n-button>
				</span>

				<div class="md:flex justify-between  w-full pr-4 space-y-2 md:space-y-0">

					<n-select placeholder="Search by" v-model:value="searchTerm" :options="searchOptions" filterable clearable />
					<n-input placeholder="Search..." v-model:value="searchValue" class="" clearable />
					<n-select placeholder="Sort by" v-model:value="sortOption" :options="sortOptions" filterable clearable :disabled="this.searchTerm === null" />
					<div class="flex justify-between">
						<n-button icon-placement="left" v-on:click="clearFilter()" class="flex md:hidden">
							<template #icon>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
								</svg>
							</template>
							<span class="">
								Clear Filter
							</span>

						</n-button>
						<span v-if="!isRequest">
							<n-button type="" @click="searchCandidates()">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
								</svg>

							</n-button>
						</span>
						<span v-else>
							<n-button type="">
								<n-spin :size="24" />
							</n-button>
						</span>
					</div>
				</div>
				<n-button icon-placement="middle" v-on:click="routeToCreate()" class="md:hidden">
					<template #icon>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
						</svg>
					</template>

				</n-button>

				<n-button icon-placement="left" v-on:click="routeToCreate()" class="hidden md:flex">
					<template #icon>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
						</svg>
					</template>
					<span class="">
						Create
					</span>

				</n-button>

			</div>

		
			<div class="overflow-x-auto">
				<n-space vertical class="pt-4">
					<n-table striped >
						<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>RPAS</th>
							<th>CAAID</th>
							<th>Email</th>
							<th>Phone</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
							<tr v-for="candidate in this.structuredCandidates" v-bind:key="candidate.id" class="cursor-pointer" @click="routeToCandidateDetails(candidate.id)">

								<td><a class="px-4">{{ candidate.id }}</a></td>
								<td>{{ `${candidate.first_name} ${candidate.last_name}`}}</td>
								<td><n-tag v-if="candidate.is_rpas">RPAS</n-tag></td>
								<td>{{ candidate.caaid ? candidate.caaid : '' }}</td>
								<td>{{candidate.email}} </td>
								<td>{{ candidate.phone }}</td>
								<td><n-button v-on:click.stop="routeToCandidateEdit(candidate.id)">Edit</n-button></td>
							</tr>
						</tbody>
					</n-table>
			</n-space>
			</div>

			<div class="flex justify-center pt-4">
				<n-space vertical class="">
					<n-pagination v-model:page="page" :page-count="this.pages" />
				</n-space>

			</div>
		</div>
		<div v-else class="flex justify-center p-5">
			<n-spin size="large"></n-spin>       
		</div>
	</div>

</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NButton, NInput, NSpace, NPagination, NSpin, NSelect, NTable, NTag} from 'naive-ui';
   import { useLoadingBar } from 'naive-ui'


	export default {
		name: 'Candidates',
		components: {
			NButton,
			NInput, 
			NSpace,
			NPagination,
			NSpin,
			NSelect,
			NTable,
			NTag
		},

		created() {
			this.loadingBar = useLoadingBar()
		},

		watch: {
			page() {
				this.searchCandidates();
			}
		},


		async mounted() {
			this.userStore = useUserStore();

			this.populateFields();

			if (this.$route.path === "/home/candidates") {
				this.loadingBar.start();
				await this.getCandidates();
				this.loadingBar.finish();
			}
			this.isLoading = false;
			

		},

		data() {
			return {
			userStore: null,
			loadingBar: null,
			isLoading: true,
			sortOption: null,
			sortOptions: [
				{
					label: "First Name A->Z",
					value: "first_name",
				},
				{
					label: "First Name Z->A",
					value: "-first_name",
				},
				{
					label: "Last Name A->Z",
					value: "last_name",
				},
				{
					label: "Last Name Z->A",
					value: "-last_name",
				},
				{
					label: "CAAID ASC",
					value: "caaid",
				},
				{
					label: "CAAID DESC",
					value: "-caaid",
				},
				{
					label: "Email A->Z",
					value: "email",
				},
				{
					label: "Email Z->A",
					value: "-email",
				},
				{
					label: "Phone A->Z",
					value: "phone",
				},
				{
					label: "Phone Z->A",
					value: "-phone",
				},
			],

			searchOptions: [
				{
				label: `First name`,
				value: 'first_name',
				},
				{
				label: `Last name`,
				value: 'last_name',
				},
		
				{
				label: `CAAID`,
				value: 'caaid',
				},
		
				{
				label: `Email`,
				value: 'email',
				},

				{
				label: `Phone`,
				value: 'phone',
				},
		
		
			],
			searchTerm: null,
			searchValue: null,
			isRequest: false,

			page: 1,
			pages: 0,
			candidates: null,
			structuredCandidates: [],


			}
		}, 
		methods: {


			/**
			 * Populates search settings based on query parameters from the sites URL.
			 */
			populateFields() {
				let alphaNumRegex = new RegExp(/\W/g)

				this.page = parseInt(this.$route.query.page)
				if (isNaN(this.page)) {
					this.page = 1;
				}

				if (this.searchOptions.some(el => el.value === this.$route.query.searchTerm)) {
					this.searchTerm = this.$route.query.searchTerm
				}
			

				if (!alphaNumRegex.test(this.$route.query.searchValue)) {
					this.searchValue = this.$route.query.searchValue
				}

				if (this.sortOptions.some(el => el.value === this.$route.query.sortOption)) {
					this.sortOption = this.$route.query.sortOption
				}
				

			},
			/**
			 * Makes a GET request to list the candidates, uses search parameters if given.
			 * 
			 * Called on page load, uses request parameters if a search has been requested via the URL.
			 */
			async getCandidates() {
				
				let requestURL = `/api/candidates/${this.page}`;
				if (this.searchTerm !== ""  && this.searchTerm !== null) {
					let sortOption = this.sortOption
					if (this.searchValue == undefined || this.searchValue == null) {
						this.searchValue = ''
					}
					if (this.sortOption === null) {
						sortOption = "id"
					}
					requestURL =  `/api/candidate/${this.page}/${this.searchValue}/${this.searchTerm}/${sortOption}`
				}
				
				let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
				this.candidates = await response.json();
				this.structuredCandidates = this.candidates.slice(0, -1);
				for (let i = 0; i < this.structuredCandidates.length; i++) {
					this.structuredCandidates[i]["name"] = `${this.structuredCandidates[i].first_name} ${this.structuredCandidates[i].last_name}`
				}
				this.pages = this.candidates[this.candidates.length - 1].num_pages


			},

			/**
			 * Clears all filters.
			 */
			clearFilter() {
				this.searchValue = null;
				this.searchTerm = null;
				this.sortOption = null;
			},

			/**
			 * Called when a search query is requested.
			 */
			async searchCandidates() {
				this.$router.push({ path: '/home/candidates', query: { page: this.page, searchTerm: this.searchTerm, searchValue: this.searchValue, sortOption: this.sortOption }})
			},

			routeToCreate() {
				this.$router.push('/home/candidates/create')

			},

			routeToCandidateDetails(candidateId) {
				this.$router.push('/home/candidates/' + candidateId)
			},

			routeToCandidateEdit(candidateId) {
				this.$router.push('/home/candidates/edit/' + candidateId)
			},
		}
	}

	
   </script>
   