<template>
	<div v-if="!isForbidden">
		<div v-if="this.$route.path.split('/').length > 3" class="flex justify-center">
			<router-view :key="$route.fullPath" class="bg-white rounded-md border"></router-view>
		</div>
		<div class="bg-white rounded-md rounded-md border" v-else>
			<div class="bg-blue-500 rounded-t-md">
				<h1 class="text-2xl text-white px-4 py-2">Examiners</h1>
			</div>
			<div class="p-4 px-8" v-if="!isLoading">
				<div class="flex justify-between md:justify-start">

					<span>
						<n-button icon-placement="left" v-on:click="clearFilter()" class="hidden md:flex">
							<template #icon>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
								</svg>
							</template>
							<span class="">
								Clear Filter
							</span>

						</n-button>
					</span>

					<div class="md:flex justify-between  w-full pr-4 space-y-2 md:space-y-0">

						<n-select placeholder="Search by" v-model:value="searchTerm" :options="searchOptions" filterable clearable />
						<n-input placeholder="Type to search..." v-model:value="searchValue" class="" clearable />
						<n-select placeholder="Sort by" v-model:value="sortOption" :options="sortOptions" filterable clearable :disabled="this.searchTerm === null || this.searchValue ===''" />
						<div class="flex justify-between">
							<n-button icon-placement="left" v-on:click="clearFilter()" class="flex md:hidden">
								<template #icon>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
									</svg>
								</template>
								<span class="">
									Clear Filter
								</span>

							</n-button>
							
							<span v-if="!isRequest">
								<n-button type="" @click="searchExaminers()">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
									</svg>

								</n-button>
							</span>
							<span v-else>
								<n-button type="">
									<n-spin :size="24" />
								</n-button>
							</span>
						</div>
					</div>
					<n-button icon-placement="middle" v-on:click="routeToCreate()" class="md:hidden">
						<template #icon>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
							</svg>
						</template>

					</n-button>

					<n-button icon-placement="left" v-on:click="routeToCreate()" class="hidden md:flex">
						<template #icon>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
							</svg>
						</template>
						<span class="">
							Create
						</span>

					</n-button>
				</div>
			
				<div class="overflow-x-auto">
					<n-space vertical class="pt-4">
					<n-table striped >
						<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>RPAS</th>
							<th>Status</th>
							<th>Expiries</th>
							<th></th>
							
						</tr>
						</thead>
						<tbody>
							<tr v-for="examiner in this.structuredExaminers" v-bind:key="examiner.id" class="cursor-pointer" @click="routeToExaminerDetails(examiner.id)">
								<td>{{ examiner.id }}</td>
								<td>{{ examiner.first_name }} {{ examiner.last_name }}</td>
								<td><n-tag v-if="examiner.rpas">RPAS</n-tag></td>
								<td><n-tag>{{ examiner.active_examiner ? "Active" : "Inactive" }}</n-tag></td>
								<td>
									<n-tooltip trigger="hover" :disabled="Object.keys(examiner.ratings).length === 0" placement="top-start" class="w-96">
										<template #trigger>
											<div class="">
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="(examiner.ratings['medical_expiry'] === undefined) || examinerRatingMap[examiner.id] === 0 ? '#dc2626': '#f97316'" class="w-7 h-7 mr-1" v-if="(examiner.ratings['medical_expiry'] === undefined) || examinerRatingMap[examiner.id] !== 1">
													<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
												</svg>
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#16a34a" class="w-7 h-7" v-else>
													<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
												</svg>
											</div>
										</template>
										<div>

											<div v-for="(ratingKey) in Object.keys(this.structuredExaminers.find(x => x.id === examiner.id).ratings)" v-bind:key="ratingKey" class="w-88" >
												<div class="flex items-center justify-between">
													<span class="flex items-center">
														<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="bestRatingDayMap[examiner.id][ratingKey] > 1 ||  bestRatingDayMap[examiner.id][ratingKey] === -1 ? '#f97316' : '#dc2626'" class="w-4 h-4" v-if="bestRatingDayMap[examiner.id][ratingKey] > 1 || bestRatingDayMap[examiner.id][ratingKey] <= 0">
															<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#dc2626" class="w-4 h-4" v-if="ratingKey === 'medical_expiry' && examiner.ratings[ratingKey] === undefined">
															<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#16a34a" class="w-4 h-4" v-if="bestRatingDayMap[examiner.id][ratingKey] === 1"> 
															<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
														</svg>
														<span v-if="bestRatingDayMap[examiner.id][ratingKey] > 1" class="pr-4">{{ bestRatingDayMap[examiner.id][ratingKey] }} Days</span>
														<span v-if="bestRatingDayMap[examiner.id][ratingKey] === -1">1 Day</span>
													</span>
													
													<span :class="bestRatingDayMap[examiner.id][ratingKey] === 0 ? 'text-red-600': ''" v-if="examiner.ratings[ratingKey] !== undefined">
														{{ratingKeyToString(ratingKey)}}  {{this.epochToDate(examiner.ratings[ratingKey])}}
													</span>
													<span class="text-red-600" v-if="ratingKey === 'medical_expiry' && examiner.ratings['medical_expiry'] === undefined">
														{{ratingKeyToString(ratingKey)}}  does not exist
													</span>
												</div>

											</div>
										</div>
										
									</n-tooltip>
								</td>
								<td><n-button v-on:click.stop="routeToExaminerEdit(examiner.id)">Edit</n-button></td>
								
							</tr>
						</tbody>
					</n-table>
				</n-space>
				</div>
				
				<div class="flex justify-center pt-4">
					<n-space vertical class="">
						<n-pagination v-model:page="page" :page-count="this.pages" />
					</n-space>

				</div>
			</div>
			<div v-else class="flex justify-center p-5">
				<n-spin size="large"></n-spin>       
			</div>
		</div>
	</div>
	<div v-else class="bg-white">
		<Forbidden></Forbidden>
	</div>


</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NButton, NInput, NSpace, NTable, NPagination, NSelect, NSpin, NTooltip, NTag } from 'naive-ui';
   import { useLoadingBar } from 'naive-ui'
   import Forbidden from '../../components/Forbidden.vue';
   import isAdmin from "../../helpers/AuthHelper.js";
	export default {
		name: 'Examiners',
		components: {
			NButton,
			NInput, 
			NSpace,
			NTable,
			NPagination,
			NSelect,
			NSpin,
			Forbidden,
			NTooltip,
			NTag
		},

		created() {
			this.loadingBar = useLoadingBar()
		},
		watch: {
			page() {
				this.searchExaminers();
			}
		},
		async mounted() {
			this.userStore = useUserStore();

			//Check permissions if on /examiners
			if (this.$route.path.split("/").length < 4) {
				if (await isAdmin()) {
					this.populateFields();

					if (this.$route.path === "/home/examiners") {
						this.loadingBar.start()
			
						await this.getExaminers();

						
					}
				} else {
					this.isForbidden = true;
				}
			}
			
			this.loadingBar.finish();
			this.isLoading = false;

			
		},

		data() {
			return {
			userStore: null,
			loadingBar: null,
			isRequest: false,
			isForbidden: false,
			isLoading: true,
			
			page: 1,
			pages: 0,
			examiners: null,
			structuredExaminers: null,
			
			examinerRatingMap: {},
			bestRatingDayMap: {},

			sortOption: null,
			sortOptions: [
				{
				label: `ID ASC`,
				value: 'id',
				},
				{
				label: `ID DESC`,
				value: '-id',
				},
				{
					label: "First Name A->Z",
					value: "first_name",
				},
				{
					label: "First Name Z->A",
					value: "-first_name",
				},
				{
					label: "Last Name A->Z",
					value: "last_name",
				},
				{
					label: "Last Name Z->A",
					value: "-last_name",
				},
			],

			searchOptions: [
				{
				label: `ID`,
				value: 'id',
				},
				{
				label: `First name`,
				value: 'first_name',
				},
				{
				label: `Last name`,
				value: 'last_name',
				},
		
			],
			searchTerm: null,
			searchValue: null,
			

			}
		},
		methods: {

			populateFields() {
				let alphaNumRegex = new RegExp(/\W/g)

				this.page = parseInt(this.$route.query.page)
				if (isNaN(this.page)) {
					this.page = 1;
				}

				if (this.searchOptions.some(el => el.value === this.$route.query.searchTerm)) {
					this.searchTerm = this.$route.query.searchTerm
				}
			

				if (!alphaNumRegex.test(this.$route.query.searchValue)) {
					this.searchValue = this.$route.query.searchValue
				}

				if (this.sortOptions.some(el => el.value === this.$route.query.sortOption)) {
					this.sortOption = this.$route.query.sortOption
				}
				

			},

			/**
			 * returns bestRating to be the most important expiry date,(0 expired, >1 days left, 1 good)
			 * Also adds the examiners ratings to a obj.
			 */
			async getBestRating(examinerIdList) {
				const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]

				let ratingResponse = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/examiners/bulkexpiry/", {
					method: 'POST',
					credentials: 'include',
					headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': cookie

                    },
					body: JSON.stringify({
                    "users_list": examinerIdList,
					})
				})
				let data = await ratingResponse.json();
				for (let i in data) {
					this.bestRatingDayMap[data[i].user_id] = data[i].ratings;
					let bestRating = 1;
					for (let ratingKey in data[i].ratings) {
						let rating = data[i].ratings[ratingKey]



						if (rating === 0 || rating === undefined) {
							bestRating = 0;
							break;
							
						} else if (rating !== 1) {
							//More days out
							if (rating > bestRating) {
								bestRating = rating;
							}
							
						}
					}
					this.examinerRatingMap[data[i].user_id] = bestRating
				}	
			},

			/**
			 * Converts a given key into text form.
			 * @param {*} key 
			 */
			ratingKeyToString(key) {
				switch(key) {
					case "medical_expiry":
						return "Medical Expiry"
					case "ga_rating":
						return "GA Expiry"
					case "airline_rating":
						return "Airline Expiry";
					case "rpas_rating":
						return "RPAS Expiry"
				}
			},

			epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},

			tagType(examinerStatus) {
                if (examinerStatus === "Pending") {
                    return "warning"
                } else if (examinerStatus === "Completed") {
                    return "success"
                } else {
                    return "error"
                }
            },

			/**
			 * Clears all filters.
			 */
			clearFilter() {
				this.searchValue = null;
				this.searchTerm = null;
				this.sortOption = null;
			},


			async getExaminers() {

				let requestURL = `/api/examiners/${this.page}`;
				if (this.searchValue !== '' && this.searchValue !== undefined && this.searchValue !== null && this.searchTerm !== ""  && this.searchTerm !== null) {
					let sortOption = this.sortOption
					if (this.sortOption === null) {
						sortOption = "-first_name"
					}
					requestURL =  `/api/examiner/${this.page}/${this.searchValue}/${this.searchTerm}/${sortOption}` //TODO remove rpasOnly
				}

				let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})

				
				this.examiners = await response.json();
				this.structuredExaminers = this.examiners.slice(0, -1);
				this.pages = this.examiners[this.examiners.length - 1].num_pages

				let examinerIdList = []
				for (let i in this.structuredExaminers) {
					examinerIdList.push(this.structuredExaminers[i].id)
					if (this.structuredExaminers[i].medical_expiry !== null) {
						this.structuredExaminers[i].ratings["medical_expiry"] = this.structuredExaminers[i].medical_expiry
					} else {
						this.structuredExaminers[i].ratings["medical_expiry"] = undefined
					}
				}
				await this.getBestRating(examinerIdList);
			},

			async searchExaminers() {
				this.$router.push({ path: '/home/examiners', query: { page: this.page, searchTerm: this.searchTerm, searchValue: this.searchValue, sortOption: this.sortOption }})
			},

			routeToExaminerEdit(examinerId) {
				this.$router.push('/home/examiners/edit/' + examinerId);
			},

			routeToExaminerDetails(examinerId) {
				this.$router.push('/home/examiners/' + examinerId)
			},

			routeToCreate() {
				this.$router.push('/home/examiners/create')
			},

		}
	}
   </script>
   