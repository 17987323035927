const candidateString = function(candidate) {
        let candidateString = `${candidate.first_name} ${candidate.last_name}`;
        if (candidate.caaid) {
            candidateString += ` (${candidate.caaid})`
        } else if (candidate.is_rpas) {
            candidateString += ` (RPAS)`
        }
        return candidateString;
}

export default candidateString;

