<template>

        <section class="h-screen bg-gray-100">
            <div class="px-6 h-full text-gray-800">
                <div
                class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
                    <div class="basis-auto xl:w-4/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0 border border-gray-200 rounded-lg bg-white">
                        <div class="bg-blue-500 rounded-t-md">
                            <h1 class="text-2xl text-white px-4 py-2">Reset Password</h1>
                        </div>
                        <div  class="px-5 pt-4 pb-5" v-if="isTokenError">
                            Your password reset request has exired. Please send another reset email. 
                            <router-link to="/forgotpassword"  class="text-blue-500 cursor-pointer underline">To reset email page</router-link>
                        </div>

                        <div class="px-5 pt-4 pb-5" v-else>
                            <n-form class="" size="large" :rules="rules" v-on:keyup.enter="submit()">
                                <div class="py-2">Enter a new password below to change your password.</div>
                                <n-form-item
                                    class=""
                                    label="Password"
                                    path="password"
                                    :validation-status="isInvalidPassword"
                                    :feedback="passwordFeedback">
                                    <n-input type="password" placeholder="" v-model:value="password" maxlength="255" show-count  show-password-on="click"/>
                                </n-form-item>
                                <n-form-item
                                    class=""
                                    label="Repeat Password"
                                    path="repeatPassword"
                                    :validation-status="isInvalidConfirmPassword"
                                    :feedback="confirmPasswordFeedback">
                                    <n-input type="password" placeholder="" v-model:value="repeatPassword" maxlength="255" show-count  show-password-on="click"/>
                                </n-form-item>


                                <div class="flex justify-between">
                                    <div class="text-center lg:text-left">
                                        <n-button
                                        class=""
                                        :disabled="isRequest"
                                        v-on:click="submit()"
                                        :loading="isRequest"
                                        >
                                            Submit
                                        </n-button>
                                    </div>
                                </div>

                                
                            </n-form>
                        </div>

                        
                    </div>

                    <div
                        class=" xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0 px-10 flex justify-center"
                    >
                        <img :src="require('../assets/garland_logo.png')" alt="Logo" class="w-50 h-50"/>
                    </div>
                </div>
            </div>
        </section> 
</template>

<script>
import { NButton, NForm, NFormItem, NInput, useMessage } from 'naive-ui'

export default {

  
  name: 'ResetPassword',
  components: {
    NButton,
    NForm,
    NFormItem,
    NInput,     
  },

    created() {
        this.message = useMessage();
    },

    mounted() {
        this.checkTokenExists();
    },

  data() {
    return {
        passwordFeedback: "",
        isInvalidPassword: "0",
        confirmPasswordFeedback: "",
        isInvalidConfirmPassword: "0",
        isRequest: false,
        isTokenError: false,

        message: null,
        password: "",
        repeatPassword: "",

        rules: {
            password: {
                required: true,
                },
            repeatPassword: {
                required: true,
            },

        }

    }
  },
  methods: {

    async checkTokenExists() {
        if (!this.$route.query.token) {
            this.isTokenError = true;
        }
    },
    
    async submit() {
        this.isRequest = true;
        this.resetErrors();
        if (this.password !== this.repeatPassword) {
            this.message.error(`Error: Passwords do not match.`);
            this.isInvalidPassword = "error";
            this.passwordFeedback = "Passwords do not match.";
            this.isInvalidConfirmPassword = "error";
            this.confirmPasswordFeedback = "Passwords do not match.";
            this.isRequest = false;
            return;
        }

        let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/password_reset/confirm/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "password": this.password,
                    "token": this.$route.query.token,
                })
            })
        if (response.status === 200) {
            this.message.success(`Password successfully changed.`);
            this.$router.push(`/`);
        } else if (response.status === 400) {
            let data = await response.json();
            let keys = Object.keys(data)

            if (keys.includes("token")) {
                this.message.error("Your password reset request has exired. Please request another password reset and try again.");
                this.isRequest = false;
                return;
            } 
            if (keys.includes("password")) {
                this.isInvalidPassword = "error";
                this.passwordFeedback = data["password"][0];
                this.message.error("Bad request, please check your inputs and try again.");
            } 
            
        } else {
            this.message.error(`Error ${response.status}: An error occurred, please try again.`);
        }
        this.isRequest = false;

        },

        resetErrors() {
            this.isInvalidPassword = "0";
            this.isInvalidConfirmPassword = "0";
            this.passwordFeedback = "";
            this.confirmPasswordFeedback = "";
        },

        

    }
}
</script>

<style>

</style>
