<template>	
    <div>
        <div v-if="isNotFound || isForbidden || isRequestError">
            <div v-if="isNotFound" class="bg-white">
                <NotFound></NotFound>
            </div>
            <div v-if="isForbidden" class="bg-white">
                <Forbidden></Forbidden>
            </div>
            <div v-if="isRequestError" class="bg-white">
                <RequestError :request-error-code="this.requestErrorCode"></RequestError>
            </div>
        </div>
        <div class="bg-white rounded-md rounded-md border w-140 text-gray-600 overflow-x-auto" v-else>
            <div class="bg-blue-500 rounded-t-md">
                <h1 class="text-2xl text-white px-4 py-2">Course {{this.course.id}}</h1>
            </div>
            <div class="px-5 py-2" v-if="!isLoading">
                <div class="flex justify-between items-center">
                    
                    
                    <div class="text-lg text-gray-600 flex items-center">
                        <div class="pr-2">
                            <n-tag>{{ this.course.courseType }}</n-tag>
                        </div>
                        <div class="text-xl">
                            {{ this.course.title }}
                        </div>
                    </div>

                    <div class="space-x-2 flex">
                        <div>
                            <NButton @click="exportCourse()" :disabled="isExportRequest" :loading="isExportRequest">
                                Export
                            </NButton>  
                        </div>
                        <NButton class="" @click="routeToCourseEdit()">Edit</NButton>      
                    </div>
                </div>

                <div class=" text-base">
                            
                            <div class="text-sm">
                                {{ this.epochToDate(this.course.startDate) }} - {{ this.epochToDate(this.course.endDate) }}
                            </div>
                        </div>

                <div class="pt-2">
                    <div class="text-base">
                        <div class="font-semibold">
                            Ground Instructor  
                        </div>
                            <div class="text-sm">
                                {{ this.course.presenter }}
                            </div>
                        </div>
                        <div class="pt-2 text-base">
                            <div class="font-semibold">
                                Venue
                            </div>
                            
                            <div class="text-sm">
                                {{ this.course.venue }}
                            </div>
                        </div>

                </div>
                <div class="pt-2">
                    <div class="text-base">
                        <div class="font-semibold">
                            Notes
                        </div>
                            <div class="text-sm">
                                <n-input type="textarea" placeholder="" v-model:value="this.course.notepad" maxlength="250" show-count disabled/>
                            </div>
                        </div>

                </div>
                <div class="pt-2 text-base">
                    <div class="flex justify-between items-center pb-2">
                        <div class="font-semibold">
                            Candidates
                        </div>
                        <n-button @click="showCourseModal = true">
                            +
                        </n-button>

                    </div>

                    <div class="overflow-x-auto" v-if="candidateCourseDetails.length !== 0">
                    <n-space vertical class="pt-4">
                    <n-table striped>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Actions</th>
                            
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="candidateCourseDetail in candidateCourseDetails" v-bind:key="candidateCourseDetail.candidate_id">
                                <td><a class="">{{ candidateCourseDetail.candidate_name }}</a></td>
                                <td class="flex space-x-2 overflow-x-scroll w-44 md:w-auto h-20 mt-6 xl:h-auto xl:mt-0">
                                    <n-tag v-if="candidateCourseDetail.letter_sent">Letter Sent</n-tag>
                                    <n-tag v-if="candidateCourseDetail.invoiced">Invoiced</n-tag>
                                    <n-tag v-if="candidateCourseDetail.has_paid">Paid</n-tag>
                                    <n-tag v-if="candidateCourseDetail.has_attended">Attended</n-tag>
                                    <n-tag v-if="candidateCourseDetail.notes !== ''">Notes</n-tag>
                                    <div v-if="candidateCourseDetail.passed !== null">
                                        <n-tag :type="candidateCourseDetail.passed ? 'info' : 'error'">{{ candidateCourseDetail.passed ? 'Passed' : 'Failed' }}</n-tag>
                                    </div>
                                    
                                </td>
                                <td class="">
                                    {{candidateCourseDetail.candidate_phone}}
                                </td>
                                <td class="">
                                    {{candidateCourseDetail.candidate_email}}
                                </td>

                                <td>
                                    <span class="flex space-x-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-blue-600 cursor-pointer" @click="showEditCandidateCourseDetail(candidateCourseDetail.id)">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>


                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-blue-600 cursor-pointer" @click="showDeleteCandidateCourseDetail(candidateCourseDetail.id, candidateCourseDetail.candidate_name)">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </n-table>
                </n-space>
                </div>
                </div>

                <div class="text-gray-400 text-sm text-right">
                    <h3 class="">Created: {{ this.epochToDate(this.course.creationDate) }}</h3>
                </div>
            </div>

            <div v-else class="flex justify-center p-5">
                <n-spin size="large"></n-spin>       
            </div>  

            <n-modal v-model:show="showCourseModal">
                <create-candidate-course-detail :course-type="course.courseType" :courseId="course.id" @show-create-candidate="this.showCreateCandidate = true" @create-candidate-course-success="createCandidateCourseDetailEvent()"></create-candidate-course-detail>         
            </n-modal>

            <n-modal v-model:show="showDeleteModal">
                <delete-candidate-course-detail :selectedCourseDetailId="this.selectedCourseDetail" :courseId="course.id" :selected-course-detail-name="this.selectedCourseCandidate" @delete-candidate-course-success="deleteCandidateCourseDetailEvent()" @close-delete-modal="this.showDeleteModal = false"></delete-candidate-course-detail>
            </n-modal>
        




            <n-modal v-model:show="showEditModal">
                <edit-candidate-course-detail :course-type="course.courseType" :selectedCourseDetailId="this.selectedCourseDetail"  @edit-candidate-course-detail-success="editCandidateCourseDetailEvent()" @show-create-candidate="this.showCreateCandidate = true"></edit-candidate-course-detail>
            </n-modal>
            

            

            <n-modal v-model:show="showCreateCandidate">
                <create-candidate class="bg-white rounded-xl" :is-modal="true" @create-candidate-submit="candidateSubmitEvent()"></create-candidate>
            </n-modal>

            <n-modal v-model:show="showLeavingModal">
                <div>TODO</div>
            </n-modal>

            
        </div>
    </div>

</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NButton, useLoadingBar, useMessage, NModal, NTag, NSpin, NTable, NSpace, NInput } from 'naive-ui';
   import  CreateCandidate  from '../Candidates/CreateCandidate.vue';
   import DeleteCandidateCourseDetail from '../../components/DeleteCandidateCourseDetail.vue';
   import CreateCandidateCourseDetail from '../../components/CreateCandidateCourseDetail.vue';
   import EditCandidateCourseDetail from '../../components/EditCandidateCourseDetail.vue';
   import { ref } from "vue"
   import Forbidden from '../../components/Forbidden.vue';
   import RequestError from '../../components/RequestError.vue';
   import NotFound from '../../components/NotFound.vue';
   import isAdmin from "../../helpers/AuthHelper.js";
	export default {
		name: 'Course',
		components: {
            NButton,
            NModal,
            CreateCandidate,
            DeleteCandidateCourseDetail,
            CreateCandidateCourseDetail,
            EditCandidateCourseDetail,
            NTag,
            NSpin,
            NTable,
            NSpace,
            Forbidden,
            NInput,
            RequestError,
            NotFound
		},

        computed: {
        },

        created() {
			this.loadingBar = useLoadingBar();
            this.message = useMessage();
		},

		async mounted() {
			this.userStore = useUserStore();

            this.handleLoadEvent();

            if (await isAdmin()) {
                await this.getCourse();
                if (!this.isNotFound && !this.isRequestError) {
                    await this.getCandidateCourseDetails();
                }
            } else {
                this.isForbidden = true;
            }
            this.isLoading = false;
		},

		data() {
			return {
			userStore: null,
            message: null,
            isForbidden: false,
            isRequest: false,
            isEditRequest: false,
            isNotFound: false,
            isRequestError: false,
            requestErrorCode: 0,

            timeoutId: null,

            course: {
			},
            showEditModal: false,
            showDeleteModal: false,
            showCourseModal: false,
            showCreateCandidate: false,
            showLeavingModal: false,
            candidateCourseDetails: [],

            candidateOptions: [],
            candidate: null,
            formValues: ref({
                notes: "",

                invoiceNumber: "",
                invoicee: "",
                hasPaid: false,
                hasAttended: false,
                isLetterSent: false
            }),

            selectedCourseDetail: undefined,
            selectedCourseCandidate: "",

            isLoading: true,
            isExportRequest: false,


			}
		},
		methods: {

            /**
			 * Displays a message based on the previous page's action.
			 */
            handleLoadEvent() {
                switch (this.userStore.event) {
                    case "create":
                        this.message.success("Course created successfully.");
                        break;
                        case "edit":
                        this.message.success("Course edited successfully.");
                        break;
                }
                this.userStore.event = null;
            },

            async handleNoteInput() {
                this.isRequest = true;
                clearTimeout(this.timeoutId);

                // Set a new timeout
                this.timeoutId = await setTimeout(this.saveNotes, 1000);
            },

            /**
             * deprecated
             */
            async saveNotes() {
                
                let data = {
                        "notepad": this.course.notepad,
                    }

                const cookie = document.cookie.split("; ")
                    .find((row) => row.startsWith("csrftoken="))
                    ?.split("=")[1]
                await fetch(process.env.VUE_APP_BACKEND_IP + "/api/course/"  + this.$route.params.id + "/notes", {
                    method: 'PATCH',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': cookie
                    },

                    body: JSON.stringify(data)
                })
                this.isRequest = false;
            },

            epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},

            async createCandidateCourseDetailEvent() {
                await this.getCandidateCourseDetails();
                this.message.success(`Course candidate added successfully`)
                this.showCourseModal = false;
            },

            async editCandidateCourseDetailEvent() {
                await this.getCandidateCourseDetails();
                this.message.success(`Course candidate edited successfully`)
                this.showEditModal = false;
            },

            async deleteCandidateCourseDetailEvent() {
                await this.getCandidateCourseDetails();
                this.showDeleteModal = false;
            },


            async candidateSubmitEvent() {
                this.showCreateCandidate = false;
            },


            async showDeleteCandidateCourseDetail(courseDetailId, courseCandidateName) {
                this.selectedCourseCandidate = courseCandidateName;
                this.selectedCourseDetail = courseDetailId;
                this.showDeleteModal = true;
            },

            async showEditCandidateCourseDetail(courseDetailId) {
                this.selectedCourseDetail = courseDetailId;
                this.showEditModal = true;
            },


            async editCandidateCourseDetail(candidateCourseId) {
                this.showEditModal = true;
                this.isEditRequest = true;
                await this.getCandidateCourseDetail(candidateCourseId);
                this.isEditRequest = false;
            },

            /**
             * Requests to get CandidateCandidateCourseDetails
             */
             async getCandidateCourseDetails() {
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/course/" + this.course.id + "/candidatedetails", {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                this.candidateCourseDetails = await response.json();
            }, 

            async getCourse() {
                this.loadingBar.start()
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/course/" + this.$route.params.id + "/", {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (response.status === 200) {
                    let data = await response.json();
                    this.course.id = data.id;
                    this.course.title = data.title;
                    this.course.courseType = data.course_type;
                    this.course.creationDate = data.date_created;
                    this.course.startDate = data.date_start;
                    this.course.endDate = data.date_end;
                    this.course.venue = data.venue;
                    this.course.presenter = data.presenter;
                    this.course.candidates = data.candidates;
                    this.course.candidateCourseDetails = data.candidatecoursedetail;
                    this.course.courseTypeId = data.course_type_id;
                    this.course.notepad = data.notepad;
                } else if (response.status === 404) {
                    this.isNotFound = true;
                } else {
                    this.requestErrorCode = response.status;
                    this.isRequestError = true;
                }

                
                this.loadingBar.finish()
            },

            async exportCourse() {
                this.isExportRequest = true;
                const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/course/" + this.course.id + "/export", {
                        method: 'POST',
                        credentials: 'include',
                        
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': cookie
                        },
                    })
                if (response.status === 200) {
                    this.message.success(`Exported successfully, please check your emails.`);
                } else {
                    this.message.error(`Export unsuccessful. Please contact us.`);
                }
                this.isExportRequest = false;
            },

            routeToCourseEdit() {
				this.$router.push('/home/courses/edit/' + this.course.id)
			},

		}
	}
   </script>
   