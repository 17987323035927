<template>
    <div class="" v-if="!isForbidden">
        <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Edit Course</h1>
		</div>
        <div v-if="!isLoading">
            <n-form class="p-4" size="large" :model="formValues" :rules="rules">

                <n-form-item
                    class=""
                    label="ID"
                    path="id"
                    >
                    <n-input placeholder="" disabled v-model:value="formValues.id"/>
                </n-form-item>

                
                    <div class="md:flex justify-between space-x-0 md:space-x-2.5">
                        <n-form-item
                            class="w-full md:w-60"
                            label="Course Type"
                            path="courseType"
                            :validation-status="isInvalidCourseType"
                            :feedback="courseTypeFeedback"
                    >
                            <n-select v-model:value="courseType" :options="courseTypeOptions" filterable clearable />
                        </n-form-item>

                        <n-form-item
                                class="w-full"
                                label="Course Title"
                                path="title"
                                :validation-status="isInvalidTitle"
                                :feedback="titleFeedback"
                            >
                                <n-input placeholder="" v-model:value="this.formValues.title" maxlength="200" show-count />
                        </n-form-item>
                        
                    </div>



                    <div class="md:flex justify-between space-x-0 md:space-x-2.5">
                        <n-form-item
                            class=""
                            label="Start Date"
                            path="startDate"
                            :validation-status="isInvalidStartDate"
                            :feedback="startDateFeedback"
                            >
                            <n-date-picker v-model:value="this.formValues.startDate" type="date" format="dd/MM/yyyy" class="w-full md:w-60" />
                        </n-form-item>
                        <n-form-item
                            class=""
                            label="End Date"
                            path="endDate"
                            :validation-status="isInvalidEndDate"
                            :feedback="endDateFeedback"
                            >
                            <n-date-picker v-model:value="this.formValues.endDate" type="date" format="dd/MM/yyyy" class="w-full md:w-60" :is-date-disabled="isDateRangeDisabled" />
                        </n-form-item>
                        
                    </div>

                    <n-form-item
                            class=""
                            label="Venue"
                            path="venue"
                            :validation-status="isInvalidVenue"
                            :feedback="venueFeedback"
                            >
                            <n-input placeholder="" v-model:value="this.formValues.venue" maxlength="200" show-count />
                    </n-form-item>

                    <n-form-item
                            class=""
                            label="Ground Instructor"
                            path="presenter"
                            :validation-status="isInvalidPresenter"
                            :feedback="presenterFeedback"
                            >
                            <n-input placeholder="" v-model:value="this.formValues.presenter" maxlength="200" show-count />
                    </n-form-item>

                    <n-form-item
                    class=""
                    label="Notes"
                    path="notepad">
                        <n-input type="textarea" placeholder="" v-model:value="this.formValues.notepad" maxlength="250" show-count/>
                    </n-form-item>

                    <div class="text-center md:text-left pt-4">
                    <n-button
                    class=""
                    :loading="isRequest"
                    v-on:click="submit()"
                    >
                        Save
                    </n-button>
                    </div>
                    </n-form>
        </div>
        <div v-else class="flex justify-center p-5 w-96">
			<n-spin size="large"></n-spin>       
		</div>  

    </div>

    <div v-else class="bg-white">
		<Forbidden></Forbidden>
	</div>

</template>


<script>
    import { NForm, NFormItem, NButton, NInput, useMessage, NDatePicker, NSelect, useLoadingBar , NSpin} from 'naive-ui'
    import { useUserStore } from '../../stores/UserStore'
    import { ref } from "vue"
    import Forbidden from '../../components/Forbidden.vue';
    import isAdmin from "../../helpers/AuthHelper.js";
export default {
	name: 'CreateCourse',
	components: {
        NForm,
        NFormItem, 
        NButton,
        NInput,
        NDatePicker,
        NSpin,
        NSelect,
        Forbidden
	},
    created() {
        this.message = useMessage();
        this.loadingBar = useLoadingBar();
    },
    
    async mounted() {
        this.loadingBar.start()
		this.userStore = useUserStore();

        if (await isAdmin()) {
            await this.getCourseTypes();
            await this.getCourse();

        } else {
            this.isForbidden = true;
        }
        this.loadingBar.finish();

        this.isLoading = false;
        
	},

	data() {
		return {
            userStore: null,
            loadingBar: null,
            isRequest: false,
            message: null,
            isLoading: true,
            isForbidden: false,

            isInvalidCourseType: "0",
            isInvalidTitle: "0",
            isInvalidStartDate: "0",
            isInvalidEndDate: "0",
            isInvalidVenue: "0",
            isInvalidPresenter: "0",
            courseTypeFeedback: "",
            titleFeedback: "",
            startDateFeedback: "",
            endDateFeedback: "",
            venueFeedback: "",
            presenterFeedback: "",

            candidates: "",
            dateCreated: null,

            courseType: "",
            courseTypeOptions: [],

            formValues: ref({
                id: "",
                title: "",
                startDate: null,
                endDate: null,
                venue: "",
                presenter: "",
                notepad: "",
                
         
            }),


            rules: {
                title: {
                    required: true,
                    message: "Please enter a title",
                    
                },
                startDate: {
                    required: true,
                    message: "Please select a start date",
       
                },
                endDate: {
                    required: true,
                    message: "Please select an end date",
        
                },
                venue: {
                    required: true,
                    message: "Please enter a venue",
                },
                presenter: {
                    required: true,
                    message: "Please enter a presenter",
                },
                courseType: {
                    required: true,
                    message: "Please select a course type",
                },

            },
        }
    },
    methods: {


        /**
         * Requests to get courseTypes, used to populate options.
         */
         async getCourseTypes() {
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/coursetype/", {
					method: 'GET',
                    credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
			let courseTypes = await response.json();
            for (let i = 0; i < courseTypes.length; i ++) {
                this.courseTypeOptions.push({
                    label: `${courseTypes[i].name}`,
                    value: courseTypes[i].id.toString(),
                })
            }
            this.courseTypeOptions.sort((a, b) => a.label.localeCompare(b.label))

        },

         /**
         * Gets a specific course for editing
         */
         async getCourse() {
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/course/" + this.$route.params.id + "/", {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
                }
            })
            let data = await response.json();
            
            this.formValues.id = data.id.toString()
            this.formValues.title = data.title
            this.candidates = data.candidatecoursedetail
            this.formValues.startDate = parseInt(data.date_start)
            this.formValues.endDate = parseInt(data.date_end)
            this.formValues.venue = data.venue
            this.formValues.presenter = data.presenter
            this.formValues.notepad = data.notepad
            this.dateCreated = parseInt(data.date_created)
            this.courseType = this.courseTypeOptions.find(e => e.label === data.course_type).value;
        },

        /**
         * PUTs a JSON containing a workorder.
         */
        async submit() {
            this.isRequest = true;
            this.resetErrors();
            let data = {
                    "title": this.formValues.title.charAt(0).toUpperCase() + this.formValues.title.slice(1),
                    "date_created": this.dateCreated,
                    "date_start": this.formValues.startDate,
                    "date_end": this.formValues.endDate,
                    "venue": this.formValues.venue.charAt(0).toUpperCase() + this.formValues.venue.slice(1),
                    "presenter": this.formValues.presenter.charAt(0).toUpperCase() + this.formValues.presenter.slice(1),
                    "candidatecoursedetail": this.candidates,
                    "course_type": parseInt(this.courseType),
                    "notepad": this.formValues.notepad
                }

            const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/course/"  + this.$route.params.id + "/", {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookie
                },

                body: JSON.stringify(data)
            })

            if (response.status === 200) {
                this.userStore.event = "edit";
                this.$router.push(`/home/courses/${this.$route.params.id}`)

            } else if (response.status === 400) {
                this.message.warning(`Create unsuccessful. Please check all inputs`);
                let data = await response.json();
                let keys = Object.keys(data);
                if (keys.includes("course_type")) {
                    this.isInvalidCourseType = "error";
                    this.courseTypeFeedback = data["course_type"][0];
                }
                if (keys.includes("title")) {
                    this.isInvalidTitle = "error";
                    this.titleFeedback = data["title"][0];
                }
                if (keys.includes("date_start")) {
                    this.isInvalidStartDate = "error";
                    this.startDateFeedback = data["date_start"][0];
                }
                if (keys.includes("date_end")) {
                    this.isInvalidEndDate = "error";
                    this.endDateFeedback = data["date_end"][0];
                }
                if (keys.includes("venue")) {
                    this.isInvalidVenue = "error";
                    this.venueFeedback = data["venue"][0];
                }
                if (keys.includes("presenter")) {
                    this.isInvalidPresenter = "error";
                    this.presenterFeedback = data["presenter"][0];
                }
                this.isRequest = false;
            } else if (response.status === 500) {
                this.message.error(`An unknown error occurred, please check your inputs and try again`);
                this.isRequest = false;
            }
            
        },      

        /**
         * Disables the planned date picker for any date before the start date
         */
         isDateRangeDisabled(ts) {
            return ts < this.formValues.startDate;
        },

        resetErrors() {
            this.isInvalidCourseType = "0";
            this.isInvalidTitle = "0";
            this.isInvalidStartDate = "0";
            this.isInvalidEndDate = "0";
            this.isInvalidVenue = "0";
            this.isInvalidPresenter = "0";
            this.courseTypeFeedback = "";
            this.titleFeedback = "";
            this.startDateFeedback = "";
            this.endDateFeedback = "";
            this.venueFeedback = "";
            this.presenterFeedback = "";

        },
    }

 
   }
   </script>
   