<template>
    <div class="">
        <div class="px-10 py-4 text-center">
            <div class="flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                </svg>
                <h1 class="text-5xl font-bold">404: Not Found.</h1>
            </div>

            
            <h2 class="pt-4 text-3xl">This page does not exist.</h2>
		</div>

    </div>

</template>


<script>
export default {
	name: 'NotFound',
	components: {
	},
    created() {
    },
    
    async mounted() {
	},

	data() {
		return {
        }
    },
    methods: {
    }
 
   }
   </script>
   