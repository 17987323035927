import { defineStore } from 'pinia'

export const useUserStore = defineStore({
    id: 'user',
    state: () => {
        return {
            
            username: "",
            id: null,
            event: null,
        }
    },

    
})
