<template>
    <section class="h-screen bg-gray-100">
    <div class="px-6 h-full text-gray-800">
        <div
        class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6"
        >
        
        
            <div class="basis-auto xl:w-4/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0 border border-gray-200 rounded-lg bg-white">
                <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Login</h1>
		</div>

        <div class="px-5 py-2">
            <n-alert title="Error" type="error"  v-if="isLoginError">
                Wrong email or password
            </n-alert>
        </div>
                <n-form class="px-5 pb-4" size="large" v-on:keyup.enter="submit()">
                    <n-form-item
                    class=""
                        label="Email"
                        :validation-status="isInvalidEmail"
                        :feedback="emailFeedback"
                        :label-style="{'font-size': 'medium'}"
                        >
                        <n-input v-model:value="email" placeholder="Email" :input-props="{ type: 'email' }" clearable @blur="validateEmail" />
                    </n-form-item>

                    <n-form-item
                        label="Password"
                        :label-style="{'font-size': 'medium'}"
                        :validation-status="isInvalidPassword"
                        :feedback="passwordFeedback"
                        >
                        <n-input v-model:value="password"
                            type="password"
                            show-password-on="click"
                            placeholder="Password"
                            @blur="validatePassword"
                        />
                    </n-form-item>

                <div class="flex justify-between items-center mb-6">
                    <div class="form-group form-check">
                        <!-- <n-checkbox 
                            v-model:checked="isRememberMe"
                            class=""
                            id="rememberMe"
                        >Remember me</n-checkbox> -->
                    </div>
                    <router-link to="/forgotpassword" class="text-blue-500 cursor-pointer underline">Reset Password</router-link>
                </div>

                <div class="text-center lg:text-left">
                    <n-button
                    class=""
                    :loading="isRequest"
                    v-on:click="submit()"
                    :disabled="this.isErrorOrWarning"
                    >
                    Login
                    </n-button>
                </div>
                </n-form>
            </div>

            <div
                class=" xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0 px-10 flex justify-center"
            >
                <img :src="require('../assets/garland_logo.png')" alt="Logo" class="w-50 h-50"/>
            </div>
        </div>
    </div>
    </section> 
</template>

<script>
import { NButton, NForm, NFormItem, NInput, NAlert } from 'naive-ui'
import { useUserStore } from '../stores/UserStore'
export default {

  
  name: 'Login',
  components: {
    NButton,
    NForm,
    NFormItem,
    NInput,
    NAlert,
    // NCheckbox,
  },

  async mounted() {
    this.userStore = useUserStore();
    const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]

    let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/me/", {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
                'X-CSRFToken': cookie
        }
    })
    if (response.status === 200) {
        this.$router.push("/home")
    }

  },

  computed: {
        isErrorOrWarning() {
            return this.isInvalidEmail !== "0" || this.isInvalidPassword !== "0";
        }
  },

  data() {
    return {
        userStore: null,
        isRequest: false,
        isInvalidEmail: "0",
        isInvalidPassword: "0",
        emailFeedback: "",
        passwordFeedback: "",
        validationStatus: "",

        isLoginError: false,

        email: "",
        password: "",
        isRememberMe: false,

    }
  },
  methods: {
        validateEmail() {
            // eslint-disable-next-line
            let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (this.email !== "") {
                if (emailRegex.test(this.email)) {
                this.isInvalidEmail = "0";
                this.emailFeedback = "";

                } else {
                    this.isInvalidEmail = "warning";
                    this.emailFeedback = "Email has the wrong format";
                }
            } else {
                this.isInvalidEmail = "error";
                this.emailFeedback = "Email cannot be empty";
            }   
        },

        validatePassword() {
            if (this.password !== "") {
                this.isInvalidPassword = "0";
                this.passwordFeedback = "";
            } else {
                this.isInvalidPassword = "error";
                this.passwordFeedback = "Password cannot be empty";
            }   
        },
        
        async submit() {
            this.isRequest = true;
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/login/", {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        
                    },
                    body: JSON.stringify({
                        "email": this.email.toLowerCase(),
                        "password": this.password,
                        "remember_me": this.isRememberMe
                    })
                })
                if (response.status === 202) {
                    let data = await response.json()
                    this.userStore.id = data.examiner_id;
                    this.$router.push("/home/")

                } else if (response.status === 400) {
                    this.isLoginError = true;
                }
                this.isRequest = false;

            },


        routeToForgotPassword() {
            this.$router.push("/forgotpassword")
        }
    },
}
</script>

<style>

</style>
