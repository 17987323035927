<template>

        <section class="h-screen bg-gray-100">
            <div class="px-6 h-full text-gray-800">
                <div
                class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
                    <div class="basis-auto xl:w-4/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0 border border-gray-200 rounded-lg bg-white">
                        <div class="bg-blue-500 rounded-t-md">
                            <h1 class="text-2xl text-white px-4 py-2">Reset Password</h1>
                        </div>

                        <div class="px-5 pt-4 pb-5">
                            <router-link class="space-x-2 flex cursor-pointer pb-5" to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600" v-on:click="this.routeToLogin()">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                </svg>
                                <div class="py-0.5">
                                    <a class="font-semibold">Back to login</a>
                                </div>
                            </router-link>
                            <n-form class="" size="large" v-on:keyup.enter="submit()">
                                <div class="py-2">Enter your email and we will send you a link to reset your password.</div>
                                <n-form-item
                                class=""
                                    label="Email"
                                    :validation-status="isInvalidEmail"
                                    :feedback="emailFeedback"
                                    :label-style="{'font-size': 'medium'}"
                                    >
                                    <n-input v-model:value="email" placeholder="Email" :input-props="{ type: 'email' }" clearable @blur="validateEmail" maxlength="255" show-count />
                                </n-form-item>

                                <div class="flex justify-between">
                                    <div class="text-center lg:text-left">
                                        <n-button
                                        class=""
                                        :disabled="isRequest"
                                        v-on:click="submit()"
                                        :loading="isRequest"
                                        >
                                            Submit
                                        </n-button>
                                    </div>
                                    
                                </div>
                                
                            </n-form>
                        </div>

                        
                    </div>

                    <div
                        class=" xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0 px-10 flex justify-center"
                    >
                        <img :src="require('../assets/garland_logo.png')" alt="Logo" class="w-50 h-50"/>
                    </div>
                </div>
            </div>
        </section> 
</template>

<script>
import { NButton, NForm, NFormItem, NInput, useMessage } from 'naive-ui'

export default {

  
  name: 'ForgotPassword',
  components: {
    NButton,
    NForm,
    NFormItem,
    NInput,     
  },

    created() {
        this.message = useMessage();
    },

  data() {
    return {
        isRequest: false,

        isInvalidEmail: "0",
        emailFeedback: "",
        validationStatus: "",
        message: null,
        email: "",
        rules: {
                firstName: {
                    required: true,
                    message: "Email cannot be empty",
                    trigger: "blur"
                },
            },
    }
  },
  methods: {
    validateEmail() {
        // eslint-disable-next-line
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.email !== "") {
            if (emailRegex.test(this.email)) {
            this.isInvalidEmail = "0";
            this.emailFeedback = "";

            } else {
                this.isInvalidEmail = "warning";
                this.emailFeedback = "Email has the wrong format";
            }
        } else {
            this.isInvalidEmail = "error";
            this.emailFeedback = "Email cannot be empty";
        }   
    },
    
    async submit() {
        this.isRequest = true;
        let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/password_reset/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": this.email.toLowerCase(),
                })
            })
        if (response.status === 200) {
            this.message.success(`Email successfully sent.`);
            this.routeToLogin();
            this.isRequest = false;
        } else {
            let data = await response.json();
            let keys = Object.keys(data)
            if (keys.includes("email")) {
                this.isInvalidEmail = "error";
                this.emailFeedback = data["email"][0];
            }
            this.message.error("Invalid email entered. Please check the email and try again.");
        }
        this.isRequest = false;

        },

    routeToLogin() {
        this.$router.push("/")
    }
    }
}
</script>

<style>

</style>
