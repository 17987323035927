<template>
	<n-loading-bar-provider>
		<n-message-provider>
			<Navbar></Navbar>
			<div class="bg-gray-100 px-2 md:px-16 lg:px-32 min-h-view py-4">
				<router-view :key="$route.fullPath"/>
			</div>
		</n-message-provider>
	</n-loading-bar-provider>
</template>


<script>
   import Navbar from '../components/Navbar.vue';
   import { useUserStore } from '../stores/UserStore'
   import { NLoadingBarProvider, NMessageProvider } from 'naive-ui';
export default {
	name: 'Home',
	components: {
		Navbar,
		NLoadingBarProvider,
		NMessageProvider
	},
	mounted() {
		this.userStore = useUserStore();
	},

	data() {
		return {
		userStore: null,

	}
  },

 
   }
   </script>
   