<template>
	<div v-if="this.$route.path.split('/').length > 3" class="flex justify-center">
		<router-view :key="$route.fullPath" class="bg-white rounded-md border"></router-view>
	</div>
	<div class="bg-white rounded-md rounded-md border" v-else>
		<div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Documents</h1>
		</div>



		<div class="p-4 px-8" v-if="!isLoading">
			<n-tabs type="line" animated  :on-update:value="tabChange"> 
				<n-tab-pane :name="document.value" :tab="document.label" v-for="document in this.documentOptions" v-bind:key="document.label">
				</n-tab-pane>
			</n-tabs>
		
			<div class="overflow-x-auto">
				<n-space vertical class="pt-4">
				<n-table striped >
					<thead>
					<tr>
						<th>Name</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
						<tr v-for="document in this.documents" v-bind:key="document.id" class="cursor-pointer" @click="download(document.file, document.name)">
							<td><a class="">{{ document.name }}</a></td>
							<td class="flex justify-center">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
								</svg>
						</td>
						</tr>
					</tbody>
				</n-table>
			</n-space>
			</div>
			
			<!-- <div class="flex justify-center pt-4">
				<n-space vertical class="">
					<n-pagination v-model:page="page" :page-count="this.pages" />
				</n-space>

			</div> -->
		</div>
		<div v-else class="flex justify-center p-5">
			<n-spin size="large"></n-spin>       
		</div>
	</div>

</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NSpace, NTable, NTabs, NTabPane, NSpin } from 'naive-ui';
   import { useLoadingBar } from 'naive-ui'
   import { saveAs } from 'file-saver';
	export default {
		name: 'Documents',
		components: {
			NSpace,
			NTable,
			NTabs,
			NTabPane,
			NSpin
		},

		created() {
			this.loadingBar = useLoadingBar()
		},

		async mounted() {
			this.userStore = useUserStore();
			this.loadingBar.start()
			await this.getDocuments();
			this.loadingBar.finish()


			this.page = parseInt(this.$route.query.page)
			if (isNaN(this.page)) {
				this.page = 1;
			}
			this.isLoading = false;

		},

		data() {
			return {
			userStore: null,
			loadingBar: null,
			isLoading: true,

			searchTerm: "",
			searchValue: null,
			
			page: 1,
			pages: 0,
			documents: null,
			documentOption: "all",
			documentOptions: [
				{
				label: `All`,
				value: 'all',
				},
				{
				label: `Labels`,
				value: 'labels',
				},
				{
				label: `Forms & Certificates`,
				value: 'forms',
				},
				{
				label: `Manuals`,
				value: 'manuals',
				},

			],

			}
		},
		methods: {

			epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},

			tagType(workorderStatus) {
                if (workorderStatus === "Pending") {
                    return "warning"
                } else if (workorderStatus === "Completed") {
                    return "success"
                } else {
                    return "error"
                }
            },

			async tabChange(val) {
				this.documentOption = val;
				await this.getDocuments();
			},


			async getDocuments() {

				let requestURL = `/api/files/`;
				if (this.documentOption !== 'all') {
					requestURL += this.documentOption;
				}

				let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
				this.documents = await response.json();
				this.documents.forEach(doc => {
					doc.file = doc.file.replace('http://', 'https://')
				});

			},

			searchDocuments() {
				this.$router.push({ path: '/home/documents', query: { page: this.page, searchTerm: this.searchTerm, documentOption: this.documentOption }})
			},

			async download(downloadLink, downloadName) {
				let response = await fetch(downloadLink, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})

				let blob = await response.blob();
				saveAs(blob, downloadName);
			},

		}
	}
   </script>
   