<template>
    <n-select placeholder="Search..." v-model:value="searchValue"  @search="handleUpdateValue" :options="searchOptions"  filterable clearable remote ref="searchBar" :loading="isLoading"/>

</template>


<script>

import { NSelect } from 'naive-ui';
export default {
	name: 'AssessmentSearchBar',
	components: {
        NSelect
	},
    created() {
    },
    
    async mounted() {

        await this.getAllAssessments();

        this.searchValue = this.initSearchValue;
        if (this.searchValue !== null) {
           await this.getAssessment(this.searchValue);
        }   

        this.isFirstLoad = false;
	},

    watch: {
        searchValue() {
            if (!this.isFirstLoad) {
                this.$emit('searchValueSelected', this.searchValue)
            }
            
        }
    },

    props: {
        initSearchValue: [Number, String]
    },

	data() {
		return {
            isFirstLoad: true,
            isLoading: false,
            assessments: [],
            searchOptions: [],
            searchValue: null,
        }
    },
    methods: {

        /**
         * Resets options and selected value when the search term is changed.
         */
        resetOptions() { 
            this.searchOptions = [];
            this.searchValue = null;
        },

        /**
         * Handles a value change in a search bar for Asssessments
         * Requests to populate the n-select after a value change.
         */
        async handleUpdateValue(query) {
            if (!query.length) {
                this.searchOptions = this.assessments;
                return;
            }
            this.isLoading = true;
            // this.searchValue = null;
            this.searchOptions = this.assessments.filter(assessment => assessment.label.toLowerCase().includes(this.$refs.searchBar.$el.childNodes[1].childNodes[0].childNodes[0].value.toLowerCase()))
        
            if (this.searchOptions.length === 0) {
                this.searchOptions.push({
                    label: `No assessments found called '${query}'`,
                    value: "",
                    disabled: true,
                    style: {
                        color: "red"
                    }
                })
            }
            this.isLoading = false;
        },

        /**
			 * Gets all assessments, called on page load
			 * @param {*} assessmentId 
			 */
			async getAllAssessments() {
				let requestURL = `/api/assessment/`;
				this.assessments = [];
				let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
				let data = await response.json();
				for (let i in data) {
					this.assessments.push({
						label: data[i].name,
						value: data[i].id,
					})
				}
				
			},

			/**
			 *  Sets searchValue to be the selected assessment.
			 */
			async getAssessment() {
				this.searchOptions = this.assessments;
				if (this.searchValue) {
                    this.searchValue = parseInt(this.searchValue)
                    let foundObj = this.searchOptions.find(obj => {
                        return obj.value === this.searchValue
                    })
                    this.searchValue = foundObj.label
				}

				
			},
    }
 
   }
   </script>
   