const isAdmin = async function() {
    let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/checkadmin/", {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return (response.status === 200);
}

export default isAdmin;

