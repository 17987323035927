<template>	
    <div v-if="isNotFound || isRequestError">
        <div v-if="isNotFound" class="bg-white">
            <NotFound></NotFound>
        </div>
        <div v-if="isRequestError" class="bg-white">
            <RequestError :request-error-code="this.requestErrorCode"></RequestError>
        </div>
    </div>
	<div class="bg-white rounded-md border w-96 text-gray-600" v-else>
        <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Candidate {{this.candidate.id}}</h1>
		</div>
        <div class="px-5 py-2 overflow-x-auto" v-if="!isLoading">
            <div class="flex justify-between">

                
                <div class="text-lg text-gray-600 flex items-center">
                    <div v-if="this.candidate.isRPAS" class="pr-2">
                        <n-tag>RPAS</n-tag>
                    </div>
                    <div class="text-xl">
                        {{ this.candidate.firstName }} {{ this.candidate.lastName }}
                    </div>
                </div>

                <div>
                    <NButton @click="routeToCandidateEdit()">Edit</NButton>      
                </div>
            </div>

            <div class="">
                <div class="text-base flex items-center">
                    <div class="font-semibold pr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                        </svg>
                    </div>
                        <div class="">
                            {{ this.candidate.email }}
                        </div>
                </div>
                <div class="text-base flex items-center">
                    <div class="font-semibold pr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                    </div>
                    
                    <div class="">
                        {{ this.candidate.phone }}
                    </div>
                </div>
            </div>

            <div v-if="this.candidate.caaid" class="pt-2 text-base">
                        <div class="font-semibold">
                            CAAID
                            </div>
                        <div class="text-sm">
                            {{ this.candidate.caaid }}
                        </div>
                    </div>

            
        </div>  
        <div v-else class="flex justify-center p-5">
			<n-spin size="large"></n-spin>       
		</div>  
	</div>


</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NButton, NTag, useLoadingBar, NSpin, useMessage } from 'naive-ui';
   import RequestError from '../../components/RequestError.vue';   
   import NotFound from '../../components/NotFound.vue';
	export default {
		name: 'Candidate',
		components: {
            NButton,
            NTag,
            NSpin,
            RequestError,
            NotFound
		},

        computed: {
        },

        created() {
			this.loadingBar = useLoadingBar();
            this.message = useMessage();
		},

		async mounted() {
			this.userStore = useUserStore();

            this.handleLoadEvent();

            await this.getCandidate();

            this.isLoading = false;
            
		},

		data() {
			return {
			userStore: null,

            isNotFound: false,
            isRequestError: false,
            requestErrorCode: 0,

            candidate: {
			},
            isLoading: true,
            message: null,


			}
		},
		methods: {
            
            /**
			 * Displays a message based on the previous page's action.
			 */
            handleLoadEvent() {
                switch (this.userStore.event) {
                    case "create":
                        this.message.success("Candidate created successfully.");
                        break;
                        case "edit":
                        this.message.success("Candidate edited successfully.");
                        break;
                }
                this.userStore.event = null;
            },

            /**
             * Makes a GET request to fill candidate details.
             */
            async getCandidate() {
                this.loadingBar.start()
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidate/" + this.$route.params.id + "/", {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                if (response.status === 200) {
                    let data = await response.json();
                    this.candidate.id = data.id
                    this.candidate.caaid = data.caaid
                    this.candidate.firstName = data.first_name
                    this.candidate.lastName = data.last_name
                    this.candidate.phone = data.phone
                    this.candidate.email = data.email
                    this.candidate.isRPAS = data.is_rpas
                } else if (response.status === 404) {
                    this.isNotFound = true;
                } else {
                    this.isRequestError = true;
                    this.requestErrorCode = response.status;
                }
                this.loadingBar.finish()
            },

            routeToCandidateEdit() {
				this.$router.push('/home/candidates/edit/' + this.candidate.id)
			},
		}
	}
   </script>
   