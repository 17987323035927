<template>
    <div class="">
        <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Edit Candidate</h1>
		</div>
        <div v-if="!isLoading">
            <n-form class="p-4" size="large" :model="formValues" :rules="rules" v-on:keyup.enter="submit()">

                <n-checkbox v-model:checked="formValues.isRPAS" class="pb-2">
                    RPAS
                </n-checkbox>

                <n-form-item
                    class=""
                    label="ID"
                    path="id"
                    >
                    <n-input placeholder="" disabled v-model:value="formValues.id"/>
                </n-form-item>

                <n-form-item
                    class=""
                    label="CAAID"
                    path="caaid"
                    :validation-status="isInvalidCAAID"
                    :feedback="caaidFeedback">
                    <n-input placeholder="" v-model:value="formValues.caaid" :disabled="formValues.isRPAS" maxlength="255" />
                </n-form-item>

                <div class="md:flex justify-between space-x-0 md:space-x-2.5">
                    <n-form-item
                        class=""
                        label="First Name"
                        path="firstName"
                        :validation-status="isInvalidFirstName"
                        :feedback="firstNameFeedback">
                        <n-input placeholder="" v-model:value="formValues.firstName" maxlength="255" show-count />
                    </n-form-item>

                    <n-form-item
                        class=""
                        label="Last Name"
                        path="lastName"
                        :validation-status="isInvalidLastName"
                        :feedback="lastNameFeedback">
                        <n-input placeholder="" v-model:value="formValues.lastName" maxlength="255" show-count />
                    </n-form-item>  
                </div>

                <n-form-item
                    class=""
                    label="Email"
                    path="email">
                    <n-input placeholder="" v-model:value="formValues.email" :input-props="{ type: 'email' }" maxlength="255" show-count />
                </n-form-item>

                <n-form-item
                    class=""
                    label="Phone"
                    path="phone">
                    <n-input placeholder="" v-model:value="formValues.phone" :input-props="{ type: 'tel' }" maxlength="255" show-count />
                </n-form-item>

                <div class="text-center md:text-left pt-8">
                <n-button
                class=""
                :disabled="isRequest"
                :loading="isRequest"
                v-on:click="submit()">
                    Save
                </n-button>
                </div>
            </n-form>
        </div>
        
        <div v-else class="flex justify-center p-5 w-96">
			<n-spin size="large"></n-spin>       
		</div>  

    </div>

</template>


<script>
    import { NForm, NFormItem, NButton, NInput, useLoadingBar, useMessage, NCheckbox, NSpin} from 'naive-ui'
    import { useUserStore } from '../../stores/UserStore'
    import { ref } from "vue"
export default {
	name: 'EditCandidate',
	components: {
        NForm,
        NFormItem, 
        NButton,
        NInput,
        NCheckbox,
        NSpin
	},

    created() {
        this.userStore = useUserStore();
        this.loadingBar = useLoadingBar();
        this.message = useMessage();
    },
    
    async mounted() {

        this.loadingBar.start()
        let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidate/" + this.$route.params.id + "/", {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        let data = await response.json();
        this.formValues.isRPAS = data.is_rpas
        this.formValues.firstName = data.first_name
        this.formValues.lastName = data.last_name
        this.formValues.caaid = data.caaid
        this.formValues.phone = data.phone
        this.formValues.email = data.email
        this.formValues.id = data.id.toString()
        this.loadingBar.finish()
        this.isLoading = false;
	},

	data() {
		return {
            userStore: null,
            isRequest: false,
            loadingBar: null,
            message: null,
            isLoading: true,

            formValues: ref({
                id: "",
                firstName: "",
                lastName: "",
                caaid: "",
                phone: "",
                email: "",
                isRPAS: false,
            }),

            isInvalidCAAID: "0",
            isInvalidFirstName: "0",
            isInvalidLastName: "0",
            caaidFeedback: "",
            firstNameFeedback: "",
            lastNameFeedback: "",

            rules: {
                firstName: {
                    required: true,
                    message: "First Name cannot be empty",
                    trigger: "blur"
                },
                lastName: {
                    required: true,
                    message: "Last Name cannot be empty",
                    trigger: "blur"
                },
                email: {
                    type: "email"
                },
                caaid: {
                    type: "number",
                },
                phone: {
                },

            },
        }
    },
    methods: {
        /**
         * PUTs candidate details if no errors are present.
         */
        async submit() {
            this.isRequest = true;
            this.resetErrors();
            
            const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]

            // if (!this.formValues.isRPAS) {
            //     if (this.formValues.caaid === "") {
            //         this.isRequest = false;
            //         this.isInvalidCAAID = "error";
            //         this.caaidFeedback = "A candidate must have either a CAAID or be marked as RPAS.";
            //         this.message.error(`A candidate must have either a CAAID or be marked as RPAS`);
            //         return;
            //     }
            // }


            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidate/" + this.$route.params.id + "/", {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookie
                },
                body: JSON.stringify({
                    "is_rpas": this.formValues.isRPAS,
                    "first_name": this.formValues.firstName.charAt(0).toUpperCase() + this.formValues.firstName.slice(1),
                    "last_name": this.formValues.lastName.charAt(0).toUpperCase() + this.formValues.lastName.slice(1),
                    "caaid": this.formValues.isRPAS || this.formValues.caaid === '' || this.formValues.caaid === null ? undefined  : this.formValues.caaid,
                    "phone": this.formValues.phone,
                    "email": this.formValues.email === '' ? null  : this.formValues.email,
                })
            })
            
            if (response.status === 200) {
                this.userStore.event = "edit";
                this.$router.push(`/home/candidates/${this.$route.params.id}`);
            } else if (response.status === 409) {
                this.isInvalidCAAID = "error";
                this.caaidFeedback = `Candidate with CAAID ${this.formValues.caaid} already exists.`;
                this.message.warning(`Candidate with CAAID ${this.formValues.caaid} already exists`);
            } else if (response.status === 400) {
                this.message.warning(`Edit unsuccessful. Please check all inputs`);
                let data = await response.json();
                let keys = Object.keys(data)
                if (keys.includes("first_name")) {
                    this.isInvalidFirstName = "error";
                    this.firstNameFeedback = data["first_name"][0];
                }
                if (keys.includes("last_name")) {
                    this.isInvalidLastName = "error";
                    this.lastNameFeedback = data["last_name"][0];
                }
                if (keys.includes("caaid")) {
                    this.isInvalidCAAID = "error";
                    this.caaidFeedback = data["caaid"][0];
                }

            } else if (response.status === 500) {
                this.message.error(`An unknown error occurred, please check your inputs and try again`);
            }
            this.isRequest = false;
        },      

        resetErrors() {
            this.isInvalidCAAID = "0";
            this.isInvalidFirstName = "0";
            this.isInvalidLastName = "0";
            this.caaidFeedback = "";
            this.firstNameFeedback = "";
            this.lastNameFeedback = "";
        },
    }

 
   }
   </script>
   