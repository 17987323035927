import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
// import About from '../views/About.vue'
import Home from '../views/Home.vue'
import WorkOrders from '../views/Workorders/WorkOrders.vue'
import Courses from '../views/Courses/Courses.vue'
import Course from '../views/Courses/Course.vue'
import CreateCourse from '../views/Courses/CreateCourse.vue'
import EditCourse from '../views/Courses/EditCourse.vue'
import Candidates from '../views/Candidates/Candidates.vue'
import CreateWorkOrder from '../views/Workorders/CreateWorkOrder.vue'
import EditWorkOrder from '../views/Workorders/EditWorkOrder.vue'
import WorkOrder from '../views/Workorders/WorkOrder.vue'
import CreateCandidate from '../views/Candidates/CreateCandidate.vue'
import EditCandidate from '../views/Candidates/EditCandidate.vue'
import Documents from '../views/Documents/Documents.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Candidate from '../views/Candidates/Candidate.vue'
import Examiners from '../views/Examiners/Examiners.vue'
import Profile from '../views/Profile.vue'
import EditExaminer from '../views/Examiners/EditExaminer.vue'
import CreateExaminer from '../views/Examiners/CreateExaminer.vue'
import MyWorkOrders from '../views/Workorders/MyWorkOrders.vue'
import NotFound from '../views/NotFound.vue'
const routes = [
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  {
    path: '/', name: "Login",
     component: Login,
    meta: { title: 'Login' }
  },
  {
    path: '/forgotpassword',
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { title: 'Reset Password' }
  },
  {
    path: '/password_reset',
    name: "ResetPassword",
    component: ResetPassword,
    meta: { title: 'Confirm Password' }
  },
  {
    path: '/home',
    name: "Home",
    component: Home,
    redirect: "/home/workorders",
    children: [{
      path: 'workorders',
      component: MyWorkOrders,
      meta: { title: 'My Workorders' },
      children: [{
        path: 'create',
        component: CreateWorkOrder,
        meta: { title: 'Create Workorder' }
      },
      {
        path: 'edit/:id',
        component: EditWorkOrder,
        meta: { title: 'Edit Workorder' }
      },
      {
        path: ':id',
        component: WorkOrder,
        meta: { title: 'Workorder Details' }
        
      },
      {
        path: 'all',
        component: WorkOrders,
        meta: { title: 'Workorders' }
      },
    
    ], 
    }, 
    {
      path: 'courses',
      components: {
        default: Courses,
      },
      meta: { title: 'Courses' },
      children: [{
        path: 'create',
        component: CreateCourse,
        meta: { title: 'Create Course' }
      },
      {
        path: ':id',
        component: Course,
        meta: { title: 'Course Details' }
      },
      {
        path: 'edit/:id',
        component: EditCourse,
        meta: { title: 'Edit Course' }
      },]
    },
    {
      path: 'documents',
      components: {
        default: Documents,
      },
      meta: { title: 'Documents' }
    },
	{
		path: 'candidates',
		components: {
			default: Candidates,
		},
    meta: { title: 'Candidates' },
    children: [{
      path: 'create',
      component: CreateCandidate,
      meta: { title: 'Create Candidate' }
    },
    {
      path: 'edit/:id',
      component: EditCandidate,
      meta: { title: 'Edit Candidate' }
    },
    {
      path: ':id',
      component: Candidate,
      meta: { title: 'Candidate Details' }
    },
  
    ], 
	},
  {
		path: 'examiners',
		components: {
			default: Examiners,
		},
    meta: { title: 'Examiners' },
    children: [{
      path: ':id',
      component: Profile,
      meta: { title: 'Profile' }
    },
    {
      path: 'edit/:id',
      component: EditExaminer,
      meta: { title: 'Edit Examiner' }
    },
    {
      path: 'create',
      component: CreateExaminer,
      meta: { title: 'Create Examiner' }
    }]
	},
	]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Flight Test NZ';
  if (to.meta.title) {
    document.title = to.meta.title + " | Flight Test NZ" || 'Flight Test NZ';
  }
  
  
  next();
});

export default router
