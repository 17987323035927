<template>	
    <div v-if="isNotFound || isForbidden || isRequestError">
		<div v-if="isNotFound" class="bg-white">
			<NotFound></NotFound>
		</div>
		<div v-if="isForbidden" class="bg-white">
			<Forbidden></Forbidden>
		</div>
		<div v-if="isRequestError" class="bg-white">
			<RequestError :request-error-code="this.requestErrorCode"></RequestError>
		</div>
	</div>
	<div class="bg-white rounded-md border w-96 text-gray-600" v-else>
        <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Workorder {{this.workorder.id}}</h1>
		</div>
        <div class="px-5 py-2 overflow-x-auto" v-if="!isLoading">
            <div class="flex justify-between items-center">
                <div v-if="this.workorder.isCombo" class="">
                    <n-tag type="primary">Combo</n-tag>
                </div>
                <div class="">
                    <n-tag :color="workorder.status === 'Deferred' ?  { color: '#ffedd5', textColor: '#c2410c', borderColor: '#fdba74' } : {}" :type="this.tagType(workorder.status)">{{ this.workorder.status }}</n-tag>
                </div>
                <div v-if="this.workorder.status === 'Completed'">
                    <n-tag :type="this.workorder.isPassed ? 'info' : 'error'">{{ this.workorder.isPassed ? 'Passed' : 'Failed' }}</n-tag>
                </div>
                <div>
                    <NButton @click="routeToWorkorderEdit">Edit</NButton>      
                </div>
            </div>

            <div class="text-red-500" v-if="this.workorder.status === 'Cancelled' || this.workorder.status === 'Deferred'">
                Reason: {{this.workorder.cancellationReason}}
            </div>

            <div class="text-green-600" v-if="this.workorder.status === 'Completed' && this.workorder.completionDate !== 'Invalid Date'" >
                Completed: {{this.workorder.completionDate}}
            </div>

            <div class="pt-2">

                <div class="pt-2 text-base">
                    <div class="font-semibold">
                            Planned Date
                    </div>
                    <div class="text-sm">
                        <div class="text-sm">
                            {{ this.workorder.plannedDate }}
                        </div>
                    </div>
                </div>

                <div class="pt-2 text-base">
                    <div class="font-semibold">
                            Examiner
                    </div>
                    <div class="text-sm">
                        <a class="text-blue-600 hover:text-blue-800 cursor-pointer" @click.stop="this.routeToExaminerDetails(this.workorder.examiner_id)">{{ this.workorder.examiner }}</a>
                    </div>
                </div>

                <div class="pt-2 text-base">
                    <div class="font-semibold">
                            Candidate
                    </div>
                    <div class="text-sm">
                        <a class="text-blue-600 hover:text-blue-800 cursor-pointer" @click.stop="this.routeToCandidate(this.workorder.candidate_id)">{{ this.workorder.candidate_name }}</a>
                    </div>
                </div>
                

                <div class="pt-2 text-base">
                    <div class="font-semibold">
                            Assessment
                            </div>
                    <div class="text-sm">
                        {{ this.workorder.assessment.name }}
                    </div>
                </div>

                <div v-if="this.workorder.organisation" class="pt-2 text-base">
                    <div class="font-semibold">
                            Organisation
                            </div>
                    <div class="text-sm">
                        {{ this.workorder.organisation }}
                    </div>
                </div>

                <div v-if="this.workorder.notes" class="pt-2 text-base">
                    <div class="font-semibold">
                        Notes
                    </div>
                    <n-input type="textarea" placeholder="" v-model:value="this.workorder.notes" maxlength="50" disabled />
                </div>


            </div>
            <div class="text-gray-400 text-sm text-right">
                    <h3 class="">Created: {{ this.epochToDate(workorder.creationDate) }}</h3>
            </div>
        </div>
        <div v-else class="flex justify-center p-5">
			<n-spin size="large"></n-spin>       
		</div>  
        
        
	</div>


</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NButton, NTag, useLoadingBar, NInput, NSpin, useMessage  } from 'naive-ui';
//    import candidateString from '../../helpers/CandidateHelper'
   import Forbidden from '../../components/Forbidden.vue';
   import RequestError from '../../components/RequestError.vue';
   import NotFound from '../../components/NotFound.vue';
	export default {
		name: 'WorkOrder',
		components: {
            NButton,
            NTag,
            NInput,
            NSpin,
            Forbidden,
            RequestError,
            NotFound
		},


        created() {
			this.loadingBar = useLoadingBar();
            this.message = useMessage();
		},

		async mounted() {
            this.loadingBar.start();

			this.userStore = useUserStore();
            this.handleLoadEvent();

            await this.getWorkorder();

            this.loadingBar.finish();
            this.isLoading = false;
		},

		data() {
			return {
			userStore: null,
            message: null,
            workorder: {
                id: "",
                creationDate: "",
                completionDate: "",
                candidate_id: -1,
                candidate_name: "",
                examiner: "",
                examiner_id: -1,
                assessment: "",
                status: "",
                cancellationReason: "",
                organisation: "",
                isCombo: "",
            
            },

            isLoading: true,
            isForbidden: false,
            isNotFound: false,
            isRequestError: false,
            requestErrorCode: 0,

			}
		},
		methods: {

            /**
			 * Displays a message based on the previous page's action.
			 */
			handleLoadEvent() {
                switch (this.userStore.event) {
                    case "create":
                        this.message.success("Workorder created successfully.");
                        break;
                        case "edit":
                        this.message.success("Workorder edited successfully.");
                        break;
                }
                this.userStore.event = null;
            },

            tagType(workorderStatus) {

                switch (workorderStatus) {
                    case "Pending":
                        return "warning";
                    case "Completed":
                        return "success";
                    case "Cancelled":
                        return "error";
                }
            },


            async getWorkorder() {
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/workorder/" + this.$route.params.id + "/", {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                if (response.status === 200) {
                    let data = await response.json();
                    data = data[0];
                    
                    this.workorder.id = data.id;
                    this.workorder.creationDate = data.creation_date;
                    this.workorder.plannedDate = this.epochToDate(data.date);
                    this.workorder.completionDate = this.epochToDate(data.completion_date);
                    this.workorder.candidate_id = data.candidate_id
                    this.workorder.candidate_name = data.candidate_name
                    this.workorder.examiner = data.examiner;
                    this.workorder.examiner_id = data.examiner_id;
                    this.workorder.assessment = data.assessment;
                    this.workorder.status = data.status;
                    this.workorder.cancellationReason = data.cancellation_reason;
                    this.workorder.organisation = data.organisation;
                    this.workorder.isCombo = data.is_combo;
                    this.workorder.notes = data.notes;
                    this.workorder.isPassed = data.passed;
                } else if (response.status === 403) {
                    this.isForbidden = true;
                } else if (response.status === 404) {
                    this.isNotFound = true;
                } else {
                    this.requestErrorCode = response.status;
                    this.isRequestError = true;
                }

                
            },

            epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},

            routeToCandidate(candidateId) {
				this.$router.push('/home/candidates/' + candidateId)
			},

            routeToExaminerDetails(examinerId) {
				this.$router.push('/home/examiners/' + examinerId)
			},

            routeToWorkorderEdit() {
				this.$router.push('/home/workorders/edit/' + this.workorder.id)
			},

		}
	}
   </script>
   