<template>
    <n-card
        style="width: 600px"
        title="Remove Candidate from Course"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        >

        <div class="pt-8">
            Are you sure you want to remove {{selectedCourseDetailName}} from the course?
        </div>
            
        <div class="flex justify-end pt-4 space-x-7">
            <n-button
            class=""
            :loading="isRequest"
            type="error"
            v-on:click="deleteCandidateDetails()"
            >
                Remove
            </n-button>

            <n-button
            secondary
            class=""
            @click="this.$emit('closeDeleteModal')"
            >
                Cancel
            </n-button>
        </div>
            
    </n-card>
</template>

<script>
   import { NButton, NCard, useMessage } from 'naive-ui';
	export default {
		name: 'DetailCandidateCourseDetail',
		components: {
            NButton,
            NCard,
		},

        computed: {
        },

        created() {
            this.message = useMessage();
		},

		async mounted() {
            
		},

        props: {
            selectedCourseDetailId: Number, 
            selectedCourseDetailName: String,
            courseId: Number,
        },

		data() {
			return {

            isRequest: false,
            selectedCourseDetail: undefined,


			}
		},
		methods: {

            async deleteCandidateDetails() {
                const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]

                this.isRequest = true;
                let response = await fetch(process.env.VUE_APP_BACKEND_IP + `/api/candidatecoursedetail/${this.selectedCourseDetailId}`, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': cookie

                    },
                });
                if (response.status === 204) {
                    let candidateDetailResponse = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/course/" + this.courseId + "/" + this.selectedCourseDetailId, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': cookie
                        },
                    })
                    if (candidateDetailResponse.status === 200) {
                        this.$emit('deleteCandidateCourseSuccess');
                        this.message.success(`${this.selectedCourseDetailName} was removed from the course.`);
                    } else {
                        this.message.warning(`Remove unsuccessful. Please check you have entered all required inputs`);
                        this.isRequest = false;
                    }


                } else { 
                    this.message.warning(`Could not remove course candidate, please try again in a few seconds.`);
                    this.isRequest = false;
                }
                
            },

		}
	}
   </script>
   