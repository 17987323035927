<template>
	<div class="px-10 border-b-2 border-grey-200">
		<div class="">
			<nav class="flex w-full justify-between py-4">
				<img :src="require('../assets/garland_logo.png')" alt="Logo" class="w-20"/>

				<n-dropdown trigger="click" :options="dropdownOptions" placement="bottom-end">
					<div class="flex items-center content-end py-3" v-on:click="this.isDropdown = !this.isDropdown">

						<n-tooltip trigger="hover">
							<template #trigger>
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="(this.ratingsDays['medical_expiry'] === undefined) || this.bestRating === 0 ? '#dc2626': '#f97316'" class="w-7 h-7 mr-1" v-if="this.bestRating !== 1 || (this.ratingsDays['medical_expiry'] === undefined)">
										<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
									</svg>
								</div>
							</template>
							<div v-for="ratingKey in Object.keys(this.ratings)" v-bind:key="ratingKey" class="w-60">
								<div class="flex items-center justify-between" v-if="this.ratingsDays[ratingKey] !== 1">
									<span class="flex items-center">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="this.ratingsDays[ratingKey] > 1 ||  this.ratingsDays[ratingKey] === -1 ? '#f97316' : '#dc2626'" class="w-4 h-4" v-if="this.ratingsDays[ratingKey] > 1 || this.ratingsDays[ratingKey] <= 0">
											<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
										</svg>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#dc2626" class="w-4 h-4" v-if="ratingKey === 'medical_expiry' && this.ratingsDays[ratingKey] === undefined">
											<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
										</svg>
										<span v-if="this.ratingsDays[ratingKey] > 1" class="pr-4">{{ this.ratingsDays[ratingKey] }} Days</span>
										<span v-if="this.ratingsDays[ratingKey] === -1">1 Day</span>
									</span>
									<span :class="this.ratingsDays[ratingKey] === 0 ? 'text-red-600': ''" v-if="this.ratingsDays[ratingKey] !== undefined">
										{{ratingKeyToString(ratingKey)}}  {{this.epochToDate(this.ratings[ratingKey])}}
									</span>
									<span class="text-red-600" v-if="ratingKey === 'medical_expiry' && this.ratingsDays[ratingKey] === undefined">
										{{ratingKeyToString(ratingKey)}}  does not exist
									</span>
									
								</div>
							</div>
						</n-tooltip>
						


						<span class="pr-2">{{this.userStore.username}}</span>
						<span>
							<span v-if="!isDropdown">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
								</svg>
							</span>
							<span v-else>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
								</svg>
							</span>
						</span>
					</div>
				</n-dropdown>

				

			</nav>
		</div>
	<div class="border-b border-grey-200 w-full">

	</div>
	<div class="hidden md:block py-1">
		<n-menu class="hidden" v-model:value="activeKey" mode="horizontal" :options="menuOptions" hidden />
	</div>
	<div  class="block md:hidden py-1">
		<n-dropdown trigger="click" :options="menuOptions" placement="bottom-start">
			<div class="flex items-center content-end py-3">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
				</svg>

			</div>
		</n-dropdown>
	</div>

	
  </div>
  
</template>
  
<script>
import { NDropdown, NMenu, NTooltip } from 'naive-ui'
import { useUserStore } from '../stores/UserStore'
import { h } from "vue";
import { RouterLink } from "vue-router";
import isAdmin from "../helpers/AuthHelper.js";
export default {
	name: 'Navbar',
	components: {
		NDropdown,
		NMenu,
		NTooltip,
	},
	data() {
		return {
			userStore: null,
			isDropdown: false,
			ratingsDays: {},
			bestRating: 1,
			dropdownOptions: [
				{
					label: "Profile",
					key: "profile",
					props: {
						onClick: () => {
							if (this.userStore.id) {
								this.$router.push("/home/examiners/" + this.userStore.id)
							}
						}
					}
				},
				
				{
					label: "Logout",
					key: "logout",
					props: {
						onClick: () => {
							this.logout();
						}
					}
				}
			],

			activeKey: "",
			menuOptions: [
				{
					label: () => h(
						RouterLink,
						{
							to: "/home/workorders"
						},
						{ default: () => "My Workorders" }
					),
					key: "workorders",
					// children: [
					// {
					// 	type: 'group',
					// 	label: "options",
					// 	key: "options",
					// 	children: [{
					// 		label: () => h(
					// 			RouterLink,
					// 			{
					// 				to: "/home/workorders/create"
					// 			},
					// 			{ default: () => "Create" }),

					// 		key: 'createworkorders',
					// 		}
					// 	]
						
					// }
					// ]
				},

				{
					label: () => h(
						RouterLink,
						{
							to: "/home/candidates"
						},
						{ default: () => "Candidates" }
					),
					key: "candidates",
				},

				{
					label: () => h(
						RouterLink,
						{
							to: "/home/documents"
						},
						{ default: () => "Documents" }),
					key: "documents",
				},

				// {
				// label: () => h(
				// 	RouterLink,
				// 	{
				// 		to: "/home/workorders/all"
				// 	},
				// 	{ default: () => "All Workorders" }
				// ),
				// key: "allWorkorders",
				// },
					
			]
		}
	},
	async created() {
		this.userStore = useUserStore();


		
	},
	async mounted() {
		await this.checkLoggedIn();
    

		let pathSize = this.$route.path.split("/").length
		this.activeKey = this.$route.path.split("/")[pathSize - 1];

		await this.checkPerms();


		await this.getRatings();
		await this.getBestRating();


	},

	methods: {
		/**
		 * Adds additional menu items if a user is an admin.
		 */
		async checkPerms() {
			if (await isAdmin()) {
				this.menuOptions.push(
					{
						label: () => h(
						RouterLink,
						{
							to: "/home/workorders/all"
						},
						{ default: () => "All Workorders" }
					),
					key: "allworkorders",
				
					},
					{
				label: () => h(
					RouterLink,
					{
						to: "/home/courses"
					},
					{ default: () => "Courses" }
				),
				key: "courses",
				},
				{
					label: () => h(
					RouterLink,
					{
						to: "/home/examiners"
					},
					{ default: () => "Examiners" }
				),
				key: "examiners",
			
				},
				)
			}
		},
		
		/**
		 * Checks if a user is logged in.
		 * 
		 * Also sets username and userstore vars.
		 */
		async checkLoggedIn() {
			const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]

			let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/me/", {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
						'X-CSRFToken': cookie
				}
			})
			let data = await response.json();
			if (response.status === 403) {
				this.$router.push("/")
			}
			this.userStore.username = `${data.first_name} ${data.last_name} ${ data.rpas ? " (RPAS)" : data.caaid === null ? "" : " (" + data.caaid +  ")"}`
			this.userStore.id = data.id
			this.username = this.userStore.username;
			if (data.medical_expiry) {
				data.ratings["medical_expiry"] = data.medical_expiry;
			}
			
			this.userStore.earliestExpiry = Math.min(...Object.values(data.ratings))

		},


		async logout() {
			const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]

			let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/logout/", {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': cookie
				}
			})

			if (response.status === 204) {
				this.userStore.$reset();
				this.$router.push("/")
			}

			},

		async getRatings() {
			let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/examiner/" + this.userStore.id, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json();

			this.ratings = data.ratings
			this.ratings["medical_expiry"] = data.medical_expiry;
		},
		
		/**
		 * Sets bestRating to be the most important expiry date,(0 expired, >1 days left, 1 good)
		 */
		async getBestRating() {
			
			let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/examiner/" + this.userStore.id + "/ratings", {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json();
			this.ratingsDays = data;
			for (let ratingKey in data) {
				let rating = data[ratingKey]
				if (rating === 0) {
					this.bestRating = 0;
					return;
				} else if (rating !== 1) {
					this.bestRating = rating;
				}
			}
		},

		ratingKeyToString(key) {
			switch(key) {
				case "medical_expiry":
					return "Medical Expiry"
				case "ga_rating":
					return "GA Expiry"
				case "airline_rating":
					return "Airline Expiry";
				case "rpas_rating":
					return "RPAS Expiry"
			}
		},

		epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},
	},

}
</script>

  