<template>
    <div class="">
        <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Create Candidate</h1>
		</div>
        <n-form class="p-4 py-8" size="large" :model="formValues" :rules="rules" v-on:keyup.enter="submit()">
            <n-checkbox v-model:checked="formValues.isRPAS" class="pb-2">
                RPAS
            </n-checkbox>

            <n-form-item
                class=""
                label="CAAID"
                path="caaid"
                :validation-status="isInvalidCAAID"
                :feedback="caaidFeedback"
                >
                <n-input placeholder="" v-model:value="formValues.caaid" :disabled="formValues.isRPAS" maxlength="255" />
            </n-form-item>

            <div class="md:flex justify-between space-x-0 md:space-x-2.5">
                <n-form-item
                    class=""
                    label="First Name"
                    path="firstName"
                    :validation-status="isInvalidFirstName"
                    :feedback="firstNameFeedback">
                    <n-input placeholder=""  v-model:value="formValues.firstName" maxlength="255" show-count/>
                </n-form-item>

                <n-form-item
                    class=""
                    label="Last Name"
                    path="lastName"
                    :validation-status="isInvalidLastName"
                    :feedback="lastNameFeedback">
                    <n-input placeholder="" v-model:value="formValues.lastName" maxlength="255" show-count />
                </n-form-item>  
            </div>     
            
            <n-form-item
                class=""
                label="Email"
                path="email">
                <n-input placeholder="" v-model:value="formValues.email" :input-props="{ type: 'email' }" maxlength="255" show-count />
            </n-form-item>

            <n-form-item
                class=""
                label="Phone"
                path="phone">
                <n-input placeholder="" v-model:value="formValues.phone" :input-props="{ type: 'tel' }" maxlength="255" show-count />
            </n-form-item>

        <div class="text-center md:text-left pt-8">
            <n-button
            class=""
            :disabled="isRequest"
            v-on:click="submit()"
            :loading="isRequest">
                <span>{{this.isModal ? "Save" : "Create Candidate"}}</span>
            </n-button>
        </div>
        </n-form>

    </div>

</template>


<script>
    import { NForm, NFormItem, NButton, NInput, useMessage, NCheckbox } from 'naive-ui'
    import { useUserStore } from '../../stores/UserStore'
    import { ref } from "vue"
export default {
	name: 'CreateCandidate',
	components: {
        NForm,
        NFormItem, 
        NButton,
        NInput,
        NCheckbox
	},
    created() {
        this.message = useMessage();
    },

    props: {
            isModal: Boolean
    },
    
    async mounted() {
		this.userStore = useUserStore();
        
	},

	data() {
		return {
            userStore: null,
            isRequest: false,
            message: null,

            formValues: ref({
                firstName: "",
                lastName: "",
                caaid: "",
                phone: "",
                email: "",
                isRPAS: false,
            }),

            isInvalidCAAID: "0",
            isInvalidFirstName: "0",
            isInvalidLastName: "0",
            caaidFeedback: "",
            firstNameFeedback: "",
            lastNameFeedback: "",

            rules: {
                firstName: {
                    required: true,
                    message: "First Name cannot be empty",
                    trigger: "blur"
                },
                lastName: {
                    required: true,
                    message: "Last Name cannot be empty",
                    trigger: "blur"
                },
                email: {
                },
                caaid: {
                    type: "number",
                },
                phone: {
                },
            },
        }
    },
    methods: {

        /**
         * POSTs candidate details if no errors are present.
         */
        async submit() {
            this.isRequest = true;
            const cookie = document.cookie.split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1]
            
            this.resetErrors();

            // if (!this.formValues.isRPAS) {
            //     if (this.formValues.caaid === "") {
            //         this.isRequest = false;
            //         this.isInvalidCAAID = "error";
            //         this.caaidFeedback = "A candidate must have either a CAAID or be marked as RPAS.";
            //         this.message.error(`A candidate must have either a CAAID or be marked as RPAS.`);
            //         return;
            //     }
            // }

            

            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidate/", {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookie
                },
                body: JSON.stringify({
                    "is_rpas": this.formValues.isRPAS,
                    "first_name": this.formValues.firstName.charAt(0).toUpperCase() + this.formValues.firstName.slice(1),
                    "last_name": this.formValues.lastName.charAt(0).toUpperCase() + this.formValues.lastName.slice(1),
                    "caaid": this.formValues.isRPAS || this.formValues.caaid === '' ? undefined  : this.formValues.caaid,
                    "phone": this.formValues.phone === '' ? null  : this.formValues.phone,
                    "email": this.formValues.email === '' ? null  : this.formValues.email,
                    
                })
            })

            if (response.status === 200) {
                if (this.isModal) {
                    this.message.success(`Candidate (${this.formValues.firstName}) was successfully created`);
                    this.$emit("createCandidateSubmit");
                } else {
                    this.userStore.event = "create";
                    let data = await response.json();
                    this.$router.push(`/home/candidates/${data.id}`);
                }
            } else if (response.status === 409) {
                this.isInvalidCAAID = "error";
                this.caaidFeedback = `Candidate with CAAID ${this.formValues.caaid} already exists.`;
                this.message.warning(`Candidate with CAAID ${this.formValues.caaid} already exists`);
            } else if (response.status === 400) {
                this.message.warning(`Create unsuccessful. Please check all inputs`);
                let data = await response.json();
                let keys = Object.keys(data)
                if (keys.includes("first_name")) {
                    this.isInvalidFirstName = "error";
                    this.firstNameFeedback = data["first_name"][0];
                }
                if (keys.includes("last_name")) {
                    this.isInvalidLastName = "error";
                    this.lastNameFeedback = data["last_name"][0];
                }

            } else if (response.status === 500) {
                this.message.error(`An unknown error occurred, please check your inputs and try again`);
            }

            this.isRequest = false;
        },     
        
        resetErrors() {
            this.isInvalidCAAID = "0";
            this.isInvalidFirstName = "0";
            this.isInvalidLastName = "0";
            this.caaidFeedback = "";
            this.firstNameFeedback = "";
            this.lastNameFeedback = "";
        },
    }
}
</script>
   