<template>
    <div class="">
      <n-config-provider :theme-overrides="theme">
        <n-message-provider>
          <router-view :key="$route.fullPath" />
        </n-message-provider>
      </n-config-provider>
    </div>

</template>

<script>
  import { NConfigProvider, NMessageProvider } from 'naive-ui'

export default {
  name: 'App',
  components: { NConfigProvider, NMessageProvider },
  data() {
    return {
      theme: {
        "common": {
          "primaryColor": "#3b82f6FF",
          "primaryColorHover": "#3B82F6FF",
          "primaryColorPressed": "#2857A4FF",
          "primaryColorSuppl": "#2857A4FF"
        },
        "Table": {
          "tdTextColor": "rgba(107, 114, 128, 1)",
          "thTextColor": "rgba(55, 65, 81, 1)"
        },
        "Button": {
          "textColorPrimary": "#FFF",
          "textColorHoverPrimary": "#FFF",
          "color": "#FFF",
          "textColorError": "#D03050FF"
        },
        "Tooltip": {
          "color": "rgba(255, 255, 255, 1)",
          "textColor": "#000000FF"
        },
        "Card": {
          "titleTextColor": "rgba(255, 255, 255, 1)"
        }
      }
    }
  }
}
</script>

<style>

</style>
