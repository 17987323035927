<template>
    <div class="" v-if="!isForbidden">
        <div class="bg-blue-500 rounded-t-md">
			<h1 class="text-2xl text-white px-4 py-2">Create Examiner</h1>
		</div>
        <n-form class="p-4" size="large" :model="formValues" :rules="rules" v-on:keyup.enter="submit()">
            <n-checkbox v-model:checked="formValues.isRPAS" class="pb-2">
                RPAS
            </n-checkbox>

            <n-form-item
                class=""
                label="CAAID"
                path="caaid"
                :validation-status="isInvalidCAAID"
                :feedback="caaidFeedback">
                <n-input placeholder="" v-model:value="formValues.caaid" maxlength="255" />
            </n-form-item>

            <div class="md:flex justify-between space-x-0 md:space-x-2.5">
                <n-form-item
                    class=""
                    label="First Name"
                    path="firstName"
                    :validation-status="isInvalidFirstName"
                    :feedback="firstNameFeedback">
                    <n-input placeholder="" v-model:value="formValues.firstName" maxlength="255" show-count />
                </n-form-item>

                <n-form-item
                    class=""
                    label="Last Name"
                    path="lastName"
                    :validation-status="isInvalidLastName"
                    :feedback="lastNameFeedback">
                    <n-input placeholder="" v-model:value="formValues.lastName" maxlength="255" show-count />
                </n-form-item>  
            </div>

            <n-form-item
                class=""
                label="Email"
                path="email"
                :validation-status="isInvalidEmail"
                :feedback="emailFeedback">
                <n-input placeholder="" v-model:value="formValues.email" :input-props="{ type: 'email' }" maxlength="255" show-count />
            </n-form-item>

            <n-form-item
                class=""
                label="Phone"
                path="phone">
                <n-input placeholder="" v-model:value="formValues.phone" :input-props="{ type: 'tel' }" maxlength="255" show-count />
            </n-form-item>


            
            <n-form-item
                class=""
                label="Pilot Licence Type"
                path="licenceType"
                :validation-status="isInvalidPilotLicence"
                :feedback="pilotLicenceFeedback">
                <n-select v-model:value="pilot_licence_type" :options="licenceOptions" filterable clearable/>
            </n-form-item>

            <n-form-item
                class=""
                label="Assessment Types"
                path="assessmentTypes">
                <n-select v-model:value="assessment_types" class="max-w-lg" :options="assessmentOptions"  @update:value="sortAssessmentsOnUpdate" multiple filterable clearable/>
            </n-form-item>

            

            <n-divider class="text-gray-600">
                    Expiries
            </n-divider>

            <n-form-item label="Expiries" >

                        <n-checkbox v-model:checked="isMedicalExpiry">
                            Medical
                        </n-checkbox>
                        <n-checkbox v-model:checked="isGAExpiry">
                            GA
                        </n-checkbox>
                        <n-checkbox v-model:checked="isAirlineExpiry">
                            Airline
                        </n-checkbox>

            </n-form-item>

            <n-form-item
                class=""
                label="Medical Expiry"
                path="date">
                <n-date-picker v-model:value="this.formValues.medical_expiry" type="date" format="dd/MM/yyyy" class="w-full" :disabled="!isMedicalExpiry" />
            </n-form-item>

            <n-form-item
                class=""
                label="GA Expiry"
                path="date">
                <n-date-picker v-model:value="this.formValues.ga_rating" type="date" format="dd/MM/yyyy" class="w-full" :disabled="!isGAExpiry" />
            </n-form-item>

            <n-form-item
                class=""
                label="Airline Expiry"
                path="date">
                <n-date-picker v-model:value="this.formValues.airline_rating" type="date" format="dd/MM/yyyy" class="w-full" :disabled="!isAirlineExpiry" />
            </n-form-item>

            <n-form-item
                class=""
                label="RPAS Expiry"
                path="date">
                <n-date-picker v-model:value="this.formValues.rpas_rating" type="date" format="dd/MM/yyyy" class="w-full" :disabled="!this.formValues.isRPAS"/>
            </n-form-item>

            


        
        <n-checkbox v-model:checked="active_examiner">
            Active
        </n-checkbox>

        <div v-if="active_examiner">
            <n-space vertical :size="12">
                <n-alert title="This Examiner will be set to Inactive" v-if="!checkExpiries()" type="warning">
                    Examiners with:
                    <ul class="list-disc">
                        <li>No expiries</li>
                        <li>No medical expiry</li>
                        <li>Expired dates</li>
                    </ul>
                    will be automatically set to Inactive
                </n-alert>
            </n-space>
        </div>

        <div class="text-center md:text-left pt-8">
            <n-button
            class=""
            :disabled="isRequest"
            v-on:click="submit()"
            :loading="isRequest">
                Create examiner
            </n-button>
        </div>
        </n-form>

    </div>
    <div v-else>
		<Forbidden></Forbidden>
	</div>

</template>


<script>
    import { NForm, NFormItem, NButton, NInput, NSelect, NDatePicker, NCheckbox, useLoadingBar, useMessage, NDivider, NSpace, NAlert} from 'naive-ui'
    import { useUserStore } from '../../stores/UserStore'
    import { ref } from "vue"
	import Forbidden from '../../components/Forbidden.vue';
    import isAdmin from "../../helpers/AuthHelper.js";
export default {
	name: 'EditExaminer',
	components: {
        NForm,
        NFormItem, 
        NButton,
        NInput,
        NDatePicker,
        NCheckbox,
        NSelect,
        NDivider,
        Forbidden,
        NAlert,
        NSpace
	},

    created() {
        this.userStore = useUserStore();
        this.loadingBar = useLoadingBar();
        this.message = useMessage();
    },
    
    async mounted() {
        this.loadingBar.start();

        if (await isAdmin()) {
            await this.getAssessments();
        } else {
            this.isForbidden = true;
        }
        
        this.loadingBar.finish();
	},

	data() {
		return {
            userStore: null,
            isRequest: false,
            loadingBar: null,
            message: null,
            isForbidden: false,
            isMedicalExpiry: false,
            isAirlineExpiry: false,
            isGAExpiry: false,

            caaidFeedback: "",
            isInvalidCAAID: "0",
            firstNameFeedback: "",
            isInvalidFirstName: "0",
            lastNameFeedback: "",
            isInvalidLastName: "0",
            emailFeedback: "",
            isInvalidEmail: "0",
            pilotLicenceFeedback: "",
            isInvalidPilotLicence: "0",

            formValues: ref({
                firstName: "",
                lastName: "",
                caaid: "",
                isRPAS: false,
                phone: "",
                email: "",
                medical_expiry: null,
                // aviation_expiry: null,
                // airline_expiry: null,
                ga_rating: null,
                airline_rating: null,
                rpas_rating: null,

            }),

			active_examiner: false,
			pilot_licence_type: "",
			assessment_types: [],

            assessmentOptions: [],
            licenceOptions: [
                {
                label: "ATPL",
                value: "ATPL",
                },
                {
                label: "CPL",
                value: "CPL",
                },
                {
                label: "RPAS",
                value: "RPAS",
                },
            ],


            rules: {
                firstName: {
                    required: true,
                    message: "First Name cannot be empty",
                    trigger: "blur"
                },
                lastName: {
                    required: true,
                    message: "Last Name cannot be empty",
                    trigger: "blur"
                },
                email: {
                    type: "email",
                    required: true,
                    trigger: "blur"
                },
                caaid: {
                },
                phone: {
                },

                licenceType: {
                    required: true,
                }

            },
        }
    },
    methods: {

        /**
         * Returns true if there are no errors with expiries. Used for a warning message.
         */
        checkExpiries() {
            let valid = true;
            let currentDate = new Date();
            if (!this.isMedicalExpiry && !this.isAirlineExpiry && !this.isGAExpiry && !this.isRPAS) {
                valid = false;
            }

            if (this.isMedicalExpiry) {
                if (this.formValues.medical_expiry <= currentDate ) {
                    valid = false;
                }
            }

            if (this.isAirlineExpiry) {
                if (this.formValues.airline_rating <= currentDate ) {
                    valid = false;
                }
            }

            if (this.isGAExpiry) {
                if (this.formValues.ga_rating <= currentDate ) {
                    valid = false;
                }
            }

            
            if (this.isRPAS) {
                if (this.formValues.rpas_rating <= currentDate ) {
                    valid = false;
                }
            }


            return valid;
        },

        async submit() {
            this.resetErrors();
            this.isRequest = true;
            const cookie = document.cookie.split("; ")
            .find((row) => row.startsWith("csrftoken="))
            ?.split("=")[1]

            let data = {
                    "first_name": this.formValues.firstName.charAt(0).toUpperCase() + this.formValues.firstName.slice(1),
                    "last_name": this.formValues.lastName.charAt(0).toUpperCase() + this.formValues.lastName.slice(1),
                    "caaid": this.formValues.caaid === '' ? null  : this.formValues.caaid,
                    "phone": this.formValues.phone === '' ? null : this.formValues.phone,
                    "rpas": this.formValues.isRPAS,
                    "email": this.formValues.email.toLowerCase(),
                    "medical_expiry": this.formValues.medical_expiry === '' ? null : this.formValues.medical_expiry,
                    "ratings": {
                    },

                    "pilot_licence_type": this.pilot_licence_type === '' ? null : this.pilot_licence_type,
                    "assessment_type": this.assessment_types,
                    "active_examiner": this.active_examiner
            }
            if (!this.isMedicalExpiry) {
                data["medical_expiry"] = null;
            }
            if (this.isAirlineExpiry) {
                data.ratings["airline_rating"] = this.formValues.airline_rating === '' || this.formValues.airline_rating === null ? undefined : this.formValues.airline_rating;
            }
            if (this.isGAExpiry) {
                data.ratings["ga_rating"] = this.formValues.ga_rating === '' || this.formValues.ga_rating === null ? undefined : this.formValues.ga_rating;
            }
            if (this.formValues.isRPAS) {
                data.ratings["rpas_rating"] = this.formValues.rpas_rating === '' || this.formValues.rpas_rating === null ? undefined : this.formValues.rpas_rating;
            }
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/examiner/", {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookie
                },
                body: JSON.stringify(data)
            })
            if (response.status === 200) {
                let data = await response.json();
                this.userStore.event = "create";
                this.$router.push(`/home/examiners/${data.id}`);

            } else if (response.status === 400) {
                this.message.warning(`Create unsuccessful. Please check all inputs`);
                let data = await response.json();
                let keys = Object.keys(data)
                if (keys.includes("first_name")) {
                    this.isInvalidFirstName = "error";
                    this.firstNameFeedback = data["first_name"][0];
                }
                if (keys.includes("last_name")) {
                    this.isInvalidLastName = "error";
                    this.lastNameFeedback = data["last_name"][0];
                }
                if (keys.includes("email")) {
                    this.isInvalidEmail = "error";
                    this.emailFeedback = data["email"][0];
                }
                if (keys.includes("pilot_licence_type")) {
                    this.isInvalidPilotLicence = "error";
                    this.pilotLicenceFeedback = data["pilot_licence_type"][0];
                }

            } else if (response.status === 500) {
                this.message.error(`An unknown error occurred, please check your inputs and try again.`);
            } else if (response.status === 406) {
                this.message.error(`Enter a CAAID or select RPAS.`);
                this.isInvalidCAAID = "error";
                this.caaidFeedback = `Enter a CAAID or select RPAS.`
            } else if (response.status === 409) {
                this.message.error(`Examiner with email ${this.formValues.email} already exists`);
                this.isInvalidEmail = "error";
                this.emailFeedback = `This email is already in use.`
            }
            
            this.isRequest = false;
        },
        
        /**
         * Requests to get Assessments, used to populate options.
         */
         async getAssessments() {
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/assessment/", {
					method: 'GET',
                    credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
			let assessments = await response.json();
            for (let i = 0; i < assessments.length; i ++) {
                this.assessmentOptions.push({
                    label: `${assessments[i].name}`,
                    value: assessments[i].id.toString(),
                })
            }
        },

        /**
         * Sorts assessment types alphabetically.
         */
        sortAssessmentsOnUpdate(value, option) {
            option.sort((a, b) => a.label.localeCompare(b.label))
            let sortedAssessments = option.map(a => a.value)
            this.assessment_types = sortedAssessments;
        },

        resetErrors() {
            this.isInvalidCAAID = "0";
            this.isInvalidFirstName = "0";
            this.isInvalidLastName = "0";
            this.isInvalidEmail = "0";
            this.caaidFeedback = "";
            this.firstNameFeedback = "";
            this.lastNameFeedback = "";
            this.emailFeedback = "";
        },
    }

 
   }
   </script>
   