<template>
    <n-select placeholder="Search..." v-model:value="searchValue"  @search="handleUpdateValue" :options="searchOptions"  filterable clearable remote ref="searchBar" :loading="isLoading"/>

</template>


<script>

import { NSelect } from 'naive-ui';
import candidateString from "../helpers/CandidateHelper.js";
export default {
	name: 'CandidateSearchBar',
	components: {
        NSelect
	},
    created() {
    },
    
    async mounted() {
        this.searchValue = this.initSearchValue;
        if (this.searchValue !== null) {
            await this.getCandidate(this.searchValue);
        }   

        this.isFirstLoad = false;
	},

    watch: {
        searchValue() {
            if (!this.isFirstLoad) {
                this.$emit('searchValueSelected', this.searchValue)
            }
            
        }
    },

    props: {
        initSearchValue: [Number, String]
    },

	data() {
		return {
            isFirstLoad: true,
            isLoading: false,
            searchOptions: [],
            searchValue: null,
        }
    },
    methods: {

        /**
         * Resets options and selected value when the search term is changed.
         */
        resetOptions() { 
            this.searchOptions = [];
            this.searchValue = null;
        },

        /**
         * Handles a value change in a search bar for Candidates and Asssessments
         * Requests to populate the n-select after a value change.
         */
        async handleUpdateValue(query) {
            if (!query.length) {
                this.searchOptions = [];
                return;
            }
            this.isLoading = true;
            // this.searchValue = null;
            let response = await fetch(process.env.VUE_APP_BACKEND_IP + "/api/candidate/1/" + query + "/first_name/first_name", {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            
            let data = await response.json();
            this.searchOptions = [];
            for (let i in data.slice(0, -1)) {
                this.searchOptions.push({
                    label: candidateString(data[i]),
                    value: data[i].id,
                })
            }
        
        
            if (this.searchOptions.length === 0) {
                this.searchOptions.push({
                    label: `No candidates found called '${query}'`,
                    value: "",
                    disabled: true,
                    style: {
                        color: "red"
                    }
                })
            }
            this.isLoading = false;
        },

        /**
			 * Gets a candidate, called on page load
			 * @param {*} candidateId 
			 */
			async getCandidate(candidateId) {
				let requestURL = `/api/candidate/` + candidateId;
				
				let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
				})
				let data = await response.json();
				this.searchOptions = [{label: `${data.first_name} ${data.last_name}${ data.is_rpas ? " (RPAS)" : data.caaid === null ? "" : " (" + data.caaid + ")"}`, value: data.id}];
                this.searchValue = data.id;
			},
    }
 
   }
   </script>
   