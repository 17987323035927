<template>
	<div v-if="!isForbidden">
		<div class="bg-white rounded-md rounded-md border">
			<div class="bg-blue-500 rounded-t-md">
				<h1 class="text-2xl text-white px-4 py-2">All Workorders</h1>
			</div>
			<div class="p-4 px-8" v-if="!isLoading">
				<div class="flex justify-between md:justify-start">
					<span>
						<n-button icon-placement="left" v-on:click="clearFilter()" class="hidden md:flex">
							<template #icon>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
								</svg>
							</template>
							<span class="">
								Clear Filter
							</span>

						</n-button>
					</span>
					<div class="md:flex justify-between  w-full pr-4 space-y-2 md:space-y-0">

						<n-select class="" placeholder="Search by" v-model:value="searchTerm" :options="searchOptions" filterable clearable />
						<div v-if="this.searchTerm === 'assessment' || this.searchTerm === 'examiner'"  class="md:w-full">
							<AssessmentSearchBar v-if="this.searchTerm === 'assessment'" ref="assessmentSearchBar" :init-search-value="this.searchValue" @search-value-selected="(value) => this.searchValue = value"></AssessmentSearchBar>
							<ExaminerSearchBar v-if="this.searchTerm === 'examiner'" ref="examinerSearchBar" :init-search-value="this.searchValue" @search-value-selected="(value) => this.searchValue = value"></ExaminerSearchBar>
						</div>
						
						<n-input v-else placeholder="Type to search..." v-model:value="searchValue" class="" clearable />
						<n-select placeholder="Sort by" v-model:value="sortOption" :options="sortOptions" filterable clearable :disabled="this.searchTerm === null || this.searchValue ===''" />
						
						<div class="flex justify-between">
							<n-button icon-placement="left" v-on:click="clearFilter()" class="flex md:hidden">
								<template #icon>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
									</svg>
								</template>
								<span class="">
									Clear Filter
								</span>

							</n-button>


							<span v-if="!isRequest" class="">
								<n-button type="" @click="searchWorkorders()">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
									</svg>

								</n-button>
							</span>
							<span v-else>
								<n-button type="">
									<n-spin :size="24" />
								</n-button>
							</span>
						</div>
					</div>


					<n-button icon-placement="middle" v-on:click="routeToCreate()" class="md:hidden">
						<template #icon>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
							</svg>
						</template>

					</n-button>


					<n-button icon-placement="left" v-on:click="routeToCreate()" class="hidden md:flex">
						<template #icon>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 21" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
							</svg>
						</template>
						<span class="">
							Create
						</span>

					</n-button>

				</div>
			
				<div class="overflow-x-auto">
					<n-space vertical class="pt-4">
					<n-table striped >
						<thead>
						<tr>
							<th>ID</th>
							<th>Planned Date</th>
							<th>Examiner</th>
							<th>Candidate</th>
							<th>Assessment</th>
							<th>Status</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
							<tr v-for="workorder in this.structuredWorkorders" v-bind:key="workorder.id" class="cursor-pointer" @click="routeToWorkorderDetails(workorder.id)">
								<td><a class="">{{ workorder.id }}</a></td>
								<td>{{ this.epochToDate(workorder.date) }}</td>
								<td><a class="text-blue-600 hover:text-blue-800 cursor-pointer" @click.stop="this.routeToExaminerDetails(workorder.examiner_id)">{{ workorder.examiner }}</a></td>
								<td><a class="text-blue-600 hover:text-blue-800 cursor-pointer" @click.stop="this.routeToCandidate(workorder.candidate_id)">{{ workorder.candidate_name }}</a></td>
								<td><a class="">{{ workorder.assessment.name }}</a></td>
								<td><n-tag :color="workorder.status === 'Deferred' ?  { color: '#ffedd5', textColor: '#c2410c', borderColor: '#fdba74' } : {}" :type="this.tagType(workorder.status)">{{ workorder.status }}</n-tag></td>
								<td><n-button v-on:click.stop="routeToWorkorderEdit(workorder.id)">Edit</n-button></td>
							</tr>
						</tbody>
					</n-table>
				</n-space>
				</div>
				
				<div class="flex justify-center pt-4">
					<n-space vertical class="">
						<n-pagination v-model:page="page" :page-count="this.pages" />
					</n-space>

				</div>
			</div>
			<div v-else class="flex justify-center p-5 w-full">
				<n-spin size="large"></n-spin>       
			</div>
		</div>
	</div>
	
	<div v-else class="bg-white">
		<Forbidden></Forbidden>
	</div>

</template>


<script>
   import { useUserStore } from '../../stores/UserStore'
   import { NButton, NInput, NSpace, NTable, NPagination, NTag, NSelect, NSpin } from 'naive-ui';
   import { useLoadingBar } from 'naive-ui'
   import Forbidden from '../../components/Forbidden.vue';
   import isAdmin from "../../helpers/AuthHelper.js";
	import AssessmentSearchBar from '../../components/AssessmentSearchBar.vue';
	import ExaminerSearchBar from '../../components/ExaminerSearchBar.vue';
	export default {
		name: 'AllWorkorders',
		components: {
			NButton,
			NInput, 
			NSpace,
			NTable,
			NPagination,
			NTag,
			NSelect,
			NSpin,
			Forbidden,
			AssessmentSearchBar,
			ExaminerSearchBar
		},

		created() {
			this.loadingBar = useLoadingBar()
		},

		watch: {
			page() {
				this.searchWorkorders();
			},
			searchTerm() {
				if (!this.firstLoad) {
					this.resetOptions();
				}
			}
		},
		async mounted() {
			this.userStore = useUserStore();

			if (await isAdmin()) {
				this.loadingBar.start()
				await this.populateFields();
				await this.getWorkorders();

			} else {
				this.isForbidden = true;
			}
			
			this.loadingBar.finish();
			this.firstLoad = false;
			this.isLoading = false;

		},

		data() {
			return {
			userStore: null,
			loadingBar: null,
			isRequest: false,
			isLoading: true,
			isForbidden: false,
			
			page: 1,
			pages: 0,
			workorders: null,
			structuredWorkorders: [],
			assessments: null,
			firstLoad: true,

			sortOption: null,
			sortOptions: [
				{
				label: `ID ASC`,
				value: 'id',
				},
				{
				label: `ID DESC`,
				value: '-id',
				},
		
				{
				label: `Examiner A->Z`,
				value: 'examiner',
				},
		
				{
				label: `Examiner Z->A`,
				value: '-examiner',
				},

				{
				label: `Candidate A->Z`,
				value: 'candidate_name',
				},
		
				{
				label: `Candidate Z->A`,
				value: '-candidate_name',
				},

				{
				label: `Assessment A->Z`,
				value: 'assessment',
				},
		
				{
				label: `Assessment Z->A`,
				value: '-assessment',
				},
			],

			searchOptions: [
				{
				label: `ID`,
				value: 'id',
				},
				{
				label: `Examiner`,
				value: 'examiner',
				},
		
				{
				label: `Candidate`,
				value: 'candidate_name',
				},
		
				{
				label: `Assessment`,
				value: 'assessment',
				},

				{
				label: `Status`,
				value: 'status',
				},
		
		
			],
			searchTerm: null,
			searchValue: null,


			}
		},
		methods: {

			/**
			 * Resets options and selected value when the search term is changed.
			 */
			resetOptions() { 
				if (this.$refs.assessmentSearchBar) {
					this.$refs.assessmentSearchBar.resetOptions();
				}
				if (this.$refs.examinerSearchBar) {
					this.$refs.examinerSearchBar.resetOptions();
				}
				this.searchValue = null;
			},

			epochToDate(date) {
				return new Date(parseInt(date)).toLocaleDateString("en-NZ")
			},

			tagType(workorderStatus) {

				switch (workorderStatus) {
					case "Pending":
						return "warning";
					case "Completed":
						return "success";
					case "Cancelled":
						return "error";
				}
            },


			async populateFields() {
				let alphaNumRegex = new RegExp(/[^\w\s]/g)

				this.page = parseInt(this.$route.query.page)
				if (isNaN(this.page)) {
					this.page = 1;
				}
				if (!alphaNumRegex.test(this.$route.query.searchValue)) {
					this.searchValue = this.$route.query.searchValue
				}

				if (this.searchOptions.some(el => el.value === this.$route.query.searchTerm)) {
					this.searchTerm = this.$route.query.searchTerm
				}

				if (this.sortOptions.some(el => el.value === this.$route.query.sortOption)) {
					this.sortOption = this.$route.query.sortOption
				}
				

			},

			async getWorkorders() {
				
				let requestURL = `/api/workorders/${this.page}`;
				if (this.searchValue !== '' && this.searchValue !== undefined && this.searchValue !== null && this.searchTerm !== ""  && this.searchTerm !== null) {
					let sortOption = this.sortOption
					if (this.sortOption === null) {
						sortOption = "id"
					}	
					
					requestURL =  `/api/workorder/${this.page}/${this.searchValue}/${this.searchTerm}/${sortOption}`
					//Specific endpoint for examiners
					if (sortOption.includes("examiner")) {
						requestURL =  `/api/workorder/examiner/${this.page}/${this.searchValue}/${this.searchTerm}/${sortOption === "examiner" ? 0 : 1}`
					}
					

				}
				let response = await fetch(process.env.VUE_APP_BACKEND_IP + requestURL, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
				})
				this.workorders = await response.json();
				this.structuredWorkorders = this.workorders.slice(0, -1);
				this.pages = this.workorders[this.workorders.length - 1].num_pages
			},

			async searchWorkorders() {
				this.$router.push({ path: '/home/workorders/all', query: { page: this.page, searchTerm: this.searchTerm, searchValue: this.searchValue, sortOption: this.sortOption }})
			},


			/**
			 * Clears all filters.
			 */
			clearFilter() {
				this.searchValue = null;
				this.searchTerm = null;
				this.sortOption = null;
			},

			routeToCreate() {
				this.$router.push('/home/workorders/create')
			},

			routeToWorkorderEdit(workorderId) {
				this.$router.push('/home/workorders/edit/' + workorderId)
			},

			routeToWorkorderDetails(workorderId) {
				this.$router.push('/home/workorders/' + workorderId)
			},
			routeToCandidate(candidateId) {
				this.$router.push('/home/candidates/' + candidateId)
			},

			routeToExaminerDetails(examinerId) {
				this.$router.push('/home/examiners/' + examinerId)
			},

		}
	}
   </script>
   