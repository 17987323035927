<template>

    <section class="h-screen bg-gray-100">
        <div class="px-6 h-full text-gray-800">
            <div
            class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
                <div class="basis-auto xl:w-4/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0 border border-gray-200 rounded-lg bg-white">
                    <div class="bg-blue-500 rounded-t-md">
                        <h1 class="text-2xl text-white px-4 py-2">404: Page Not Found</h1>
                    </div>
                    <div  class="px-5 pt-4 pb-5">
                        Oops! Something went wrong. This page does not exist. 
                    </div>
                
                </div>

                <div
                    class=" xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0 px-10 flex justify-center"
                >
                    <img :src="require('../assets/garland_logo.png')" alt="Logo" class="w-50 h-50"/>
                </div>
            </div>
        </div>
    </section> 
</template>

<script>
import {} from 'naive-ui'

export default {


name: 'ResetPassword',
components: {   
},

created() {
},


data() {
return {

}
},
methods: {

}
}
</script>

<style>

</style>
