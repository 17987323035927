<template>
    <div class="">
        <div class="px-10 py-4 text-center">
            <div class="flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>

                <h1 class="text-5xl font-bold">403: Forbidden</h1>
            </div>

            
            <h2 class="pt-4 text-3xl">Access to this page is forbidden.</h2>
		</div>

    </div>

</template>


<script>
export default {
	name: 'Forbidden',
	components: {
	},
    created() {
    },
    
    async mounted() {
	},

	data() {
		return {
        }
    },
    methods: {
    }
 
   }
   </script>
   